import {useState} from "react";
import {faEye, faEyeSlash, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface BookerHiddenDataProps {
    data: string;
    icon: IconDefinition;
    regexp: string | RegExp;
}

export function BookerHiddenData({data, icon, regexp}: BookerHiddenDataProps) {
    const [display, setDisplay] = useState<boolean>(false);
    return (
        <>
            <label className="input-group">
                <span>
                    <FontAwesomeIcon icon={icon}/>
                </span>
                <input type="text" disabled={true} value={display ? data : data.replace(regexp,'*')} className="input input-bordered"/>
                <button className="btn btn-square" onClick={() => setDisplay(!display)}>
                    <FontAwesomeIcon icon={display ? faEyeSlash : faEye}/>
                </button>
            </label>
        </>
    )
}