import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarCheck, faClock, faPaperPlane, faUser} from "@fortawesome/free-solid-svg-icons";
import {SelectHoursField} from "./components/SelectHours";
import { WorkerSelectorField} from "./components/WorkerSelector";
import * as Yup from 'yup';
import {Formik, FormikProps} from "formik";
import {CalendarField} from "./components/CalendarField";
import {Worker} from '../models/worker';
import {useEffect, useRef, useState} from "react";
import {getAvailableHours, getWorkers} from "./booking-api";
import {useParams} from "react-router-dom";
import moment from "moment";
import {Errors} from "./components/Errors";
import ReCAPTCHA from "react-google-recaptcha";
import {WorkerInformation} from "./components/WorkerInformation";
import {SelectReservationTime} from "./components/SelectReservationTime";

const RECAPTCHA_SITE_KEY: string = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

export interface BookingFormData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    additionalInformation: string;
    selectedDate: Date | string;
    selectedTime: string;
    officeId: number;
    selectedWorker: number | undefined;
    recaptcha: string;
    accept: boolean;
}

const BookingFormSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Imię powinno składać się z conajmniej 2 znaków')
        .max(50, 'Imię powinno mieć maksimum 50 znaków')
        .trim()
        .required('To polę jest wymagane'),
    lastName: Yup.string()
        .min(2, 'Nazwisko powinno składać się z conajmniej 2 znaków')
        .max(50, 'Nazwisko powinno mieć maksimum 50 znaków')
        .trim()
        .required('To polę jest wymagane'),
    email: Yup.string()
        .min(2, 'Wprowadzony dane nie są poprawnym emailem.')
        .email('To nie jest poprawny adres email')
        .trim()
        .required('To polę jest wymagane'),
    phoneNumber: Yup.string()
        .matches(/(\+48)?[0-9]{9}/,"To nie jest poprawny numer telefonu (akceptowalne są 9 cyfr)")
        .trim()
        .required('To polę jest wymagane'),
    additionalInformation: Yup.string()
        .max(255, 'Dodatkowe informacje powinny mieć maksimum 255 znaków'),
    selectedDate: Yup.string(),
    selectedTime: Yup.string(),
    accept: Yup.boolean().oneOf([true], 'Musisz wyrazć zgodę na przetwarzanie danych osobowych')
    // selectedWorker: Yup.string(),
})

export function BookingForm(params: { onSubmit?: (values: BookingFormData) => void }) {

    const {workerUrl} = useParams();
    const formRef = useRef<FormikProps<BookingFormData>>(null);


    const defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() + 1);

    const initialValues: BookingFormData = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        additionalInformation: '',
        selectedDate: defaultDate,
        selectedTime: '',
        officeId: 0,
        selectedWorker: 0,
        recaptcha: '',
        accept: false
    }

    useEffect(() => {
        if (workerUrl) {
            getWorkers({urlName: workerUrl})
                .then((data) => {
                    if(data && data.length === 1) {
                        formRef.current && formRef.current.setFieldValue('selectedWorker', data[0].id);
                    }
                })
        }
    }, [workerUrl])


    return (
        <>
            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={BookingFormSchema}
                onSubmit={(values) => {
                    params.onSubmit && params.onSubmit(values);
                }}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={'flex justify-center flex-col xl:flex-row'}>
                            <div className={'px-4'}>
                                <h2 className={'py-4 text-lg md:text-center lg:text-left'}>
                                    <FontAwesomeIcon icon={faCalendarCheck}/> Data wizyty
                                </h2>
                                <CalendarField
                                    value={values.selectedDate as Date}
                                    onChange={(x) => {
                                        setFieldValue && setFieldValue('selectedDate', x);
                                    }}
                                    setFieldValue={setFieldValue}/>

                                <h2 className={'py-4 text-lg md:text-center lg:text-left'}>
                                    <FontAwesomeIcon icon={faClock}/> Dostępne godziny
                                </h2>
                                <SelectReservationTime
                                    timeName={'selectedTime'}
                                    officeName={'officeId'}
                                    selectedWorker={values.selectedWorker}
                                    selectedDay={values.selectedDate}/>
                            </div>
                            <div className={'px-4'}>
                                <h2 className={'py-4 text-lg md:text-center lg:text-left'}>
                                    <FontAwesomeIcon icon={faCalendarCheck}/> Wybierz terapeutę
                                </h2>
                                <WorkerSelectorField
                                    name={'selectedWorker'}
                                    onChange={handleChange}
                                    value={values.selectedWorker}/>
                                <WorkerInformation workerId={values.selectedWorker}/>
                                <h2 className={'py-4 text-lg md:text-center lg:text-left'}>
                                    <FontAwesomeIcon icon={faUser}/> Informacje kontaktowe
                                </h2>
                                <div className="grid gap-1 lg:grid-cols-2 md:grid-cols-1">
                                    <div className="form-control w-full xl:max-w-xs sm:w-full">
                                        <label className="label">
                                            <span className="label-text">Imię</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            onChange={handleChange}
                                            value={values.firstName}
                                            className={`
                                                input input-bordered w-full
                                                ${errors.firstName && touched.firstName ? 'border-red-600' : ''}
                                            `}/>
                                        {errors.firstName && touched.firstName ? (
                                            <Errors errors={errors.firstName}/>
                                        ) : null}
                                    </div>
                                    <div className="form-control  xl:max-w-xs sm:w-full">
                                        <label className="label">
                                            <span className="label-text">Nazwisko</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            className={`
                                                input input-bordered w-full
                                                ${errors.lastName && touched.lastName ? 'border-red-600' : ''}
                                            `}/>
                                        {errors.lastName && touched.lastName ? (
                                            <Errors errors={errors.lastName}/>
                                        ) : null}
                                    </div>
                                    <div className="form-control xl:max-w-xs sm:w-full">
                                        <label className="label">
                                            <span className="label-text">Email</span>
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            className={`
                                                input input-bordered w-full
                                                ${errors.email && touched.email ? 'border-red-600' : ''}
                                            `}/>
                                        {errors.email && touched.email ? (
                                            <Errors errors={errors.email}/>
                                        ) : null}
                                    </div>
                                    <div className="form-control xl:max-w-xs sm:w-full">
                                        <label className="label">
                                            <span className="label-text">Telefon kontaktowy</span>
                                        </label>
                                        <input
                                            name="phoneNumber"
                                            onChange={handleChange}
                                            type="text"
                                            value={values.phoneNumber}
                                            className={`
                                                input input-bordered w-full
                                                ${errors.phoneNumber && touched.phoneNumber ? 'border-red-600' : ''}
                                            `}/>
                                        {errors.phoneNumber && touched.phoneNumber ? (
                                            <Errors errors={errors.phoneNumber}/>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Dodatkowe informacje</span>
                                    </label>
                                    <textarea
                                        name={'additionalInformation'}
                                        onChange={handleChange}
                                        value={values.additionalInformation}
                                        className={`
                                                textarea textarea-bordered h-24
                                                ${errors.email && touched.email ? 'border-red-600' : ''}
                                            `}
                                    ></textarea>
                                    {errors.additionalInformation && touched.additionalInformation ? (
                                        <Errors errors={errors.additionalInformation}/>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className={'text-center p-5 md:w-3/5 md:mx-auto'}>
                                <div className="form-control">
                                    <label className="label cursor-pointer">
                                        <input
                                            type="checkbox"
                                            className="checkbox checkbox-info mx-4"
                                            name={'accept'}
                                            onChange={handleChange}
                                        />
                                        <div className={'break-words text-left'}>
                                            Wyrażam zgodę na przetwarzanie moich danych osobowych
                                        </div>
                                    </label>
                                </div>
                            <div className={'text-sm text-left'}>
                                Wypełnienie formularza oznacza, że podane w nim dane osobowe będą przetwarzane w celu rezerwacji wizyty i ewentualnego kontaktu w celu jej potwierdzenia.
                                Zasady przetwarzania i informacje o administratorze znajdziesz
                                <a target={'_blank'} rel="noreferrer" href={'/polityka_prywatności_2022_07_11.pdf'}>tutaj</a>.
                            </div>
                            {errors.accept && touched.accept ? (
                                <Errors errors={errors.accept}/>
                            ) : null}
                        </div>
                        <div className={'text-center p-5'}>
                            <ReCAPTCHA
                                className={'flex justify-center'}
                                sitekey={RECAPTCHA_SITE_KEY}
                                onChange={(response:string | null) => { setFieldValue("recaptcha", response); }}
                            />
                        </div>
                        <div className={'text-center py-2'}>
                            <div className="divider w-full">
                                <button className={'btn btn-send-form'} type={'submit'}>
                                    <FontAwesomeIcon icon={faPaperPlane}/>
                                    Wyślij
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}