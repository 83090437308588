import {iconStatus, Reservation, translateStatus} from "../../../models/reservation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

export function CalendarReservationModal({reservation, onClose}: { reservation: Reservation, onClose: () => void }) {
    return <>
        <div className="modal modal-open z-50 text-gray-900">
            <div className="modal-box">
                <h3 className="font-bold text-lg">
                    <FontAwesomeIcon icon={iconStatus(reservation.status)}/> Rezerwacja {reservation.id}
                </h3>
                <p className="py-4">
                    Status: <em className={'font-bold'}>{translateStatus(reservation.status)}</em>,
                    Pracownik <em>{reservation.worker.name}</em>
                </p>
                <p className="py-4">
                    <em>{reservation.additionalInfo || 'Brak dodatkowych informacji'}</em>
                </p>
                <div className="modal-action">
                    <Link className={'btn'} to={`/dashboard/reservation/${reservation.id}`}>
                        Przejdź do podsumowana
                    </Link>
                    <label onClick={onClose} className="btn">Zamknij</label>
                </div>
            </div>
        </div>
    </>
}