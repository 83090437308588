import moment from "moment";

interface ReservationPeriodProps {
    dateFrom: string | Date;
    dateTo: string | Date;
}
export function ReservationPeriod(props: ReservationPeriodProps) {

    const {dateFrom, dateTo} = props;

    const startDate = moment(dateFrom);
    const endDate = moment(dateTo);

    const isSameDay = startDate.format('YYYY-MM-DD') === endDate.format('YYYY-MM-DD');

    if (isSameDay) {
        return (
            <>
                {startDate.format('DD-MM-YYYY')} {startDate.format('HH:mm:ss')} - {endDate.format('HH:mm:ss')}
            </>
        )
    } else {
        return (
            <>
                {startDate.format('DD-MM-YYYY HH:mm:ss')} - {endDate.format('DD-MM-YYYY HH:mm:ss')}
            </>
        )
    }

}