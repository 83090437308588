import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getOffices, getWorker, getWorkerAvailabilitiesByWorker} from "../../../MainBooking/booking-api";
import {WorkerAvailabilities} from "../../../models/worker-availabilities";
import {Worker} from "../../../models/worker";
import moment from "moment";
import {Office} from "../../../models/office";
import {createWorkerWorkingHours, removeWorkingTime} from "../../dashboard-api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

const days: {[k: string]: string} = {
    'monday': 'Poniedziałek',
    'tuesday': 'Wtorek',
    'wednesday': 'Środa',
    'thursday': 'Czwartek',
    'friday': 'Piątek',
    'saturday': 'Sobota',
    'sunday': 'Niedziela'
}

const displayDate = (s: string) => moment(s).format('HH:mm:ss');

export function WorkerHourEditor() {

    const {id} = useParams();

    const [worker, setWorker] = useState<Worker>({} as Worker);
    const [workerAvilivilities, setWorkerAvilivilities] = useState<WorkerAvailabilities[]>([]);
    const [offices, setOffices] = useState<Office[]>([]);

    const [formValue, setFormValue] = useState<{
        startTime?: string;
        endTime?: string;
        office?: string;
        type?: string;
    }>({ });

    const initWorkerTime = (workerId: string | number) => {
        return getWorkerAvailabilitiesByWorker(workerId)
            .then((w) => setWorkerAvilivilities(w));
    }

    useEffect(() => {
        id && getWorker(id)
            .then((workerData) => {
                setWorker(workerData);
                return workerData.id
            })
            .then((w) => {
                return initWorkerTime(w);
            })
            .then((items) => {
                return getOffices();
            })
            .then((e) => {
                setOffices(e);
            })
    }, [id]);

    const updateForm = (name: 'startTime' | 'endTime' | 'office' | 'type', value: unknown) => {

        let element = {};
        // @ts-ignore
        element[name] = value;

        setFormValue({
            ...formValue,
            ...element
        });
    }

    const deleteTime = (wa: WorkerAvailabilities) => {
        if (window.confirm(`Usunąć czas pracy ${wa.type} ${wa.office.name}`)) {
            removeWorkingTime(wa.id)
                .then(() => {
                    id && initWorkerTime(id);
                })
        }
    }

    return (
        <>
            <div className={'card bg-base-100 shadow-xl'}>
                {
                    worker && (
                        <div className="card-body">
                            <h2 className="card-title">{worker.name} - Godziny pracy</h2>
                        </div>
                    )

                }
                {
                        <table className="table table-zebra w-full">
                            <thead>
                            <tr>
                                <td>Dzień</td>
                                <th>Start pracy</th>
                                <th>Koniec pracy</th>
                                <th>Gabinet</th>
                                <th>Akcje</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                workerAvilivilities.length > 0 ? workerAvilivilities.map((wa) => (
                                   <tr key={`v_${wa.id}`}>
                                       <td>{days[wa.type]}</td>
                                       <td>{displayDate(wa.startTime)}</td>
                                       <td>{displayDate(wa.endTime)}</td>
                                       <td>{wa.office.name}</td>
                                       <td>
                                           <button className={'btn btn-primary'} onClick={() => deleteTime(wa)}>
                                               <FontAwesomeIcon icon={faTrash}/>
                                           </button>
                                       </td>
                                   </tr>
                                )) : (<tr><td colSpan={5}>Brak danych</td></tr>)
                            }
                            </tbody>
                        </table>
                }
            </div>
            <div className={'card bg-base-100 shadow-xl my-2'}>
                <div className="card-body">
                    <h2 className="card-title">Dodaj dzień pracy</h2>
                    <form onSubmit={(event) => {
                        event.preventDefault();

                        const sendingData = {
                            ...formValue,
                            worker: `/api/workers/${id}`
                        }

                        createWorkerWorkingHours(sendingData)
                            .then((data) => {
                                id && initWorkerTime(id);
                            });
                    }}>
                        <div className={'flex flex-row gap-1 justify-center'}>
                            <div className="form-control w-full max-w-xs">
                                <label className="label">
                                    <span className="label-text">Dzień tygodnia</span>
                                </label>
                                <select
                                    onChange={(e) => updateForm('type', e.currentTarget.value)}
                                    className="flex-1 input-bordered select w-full max-w-xs"
                                    name={'day'}
                                >
                                    <option value={undefined}></option>
                                    <option value={'monday'}>Poniedziałek</option>
                                    <option value={'tuesday'}>Wtorek</option>
                                    <option value={'wednesday'}>Środa</option>
                                    <option value={'thursday'}>Czwartek</option>
                                    <option value={'friday'}>Piątek</option>
                                    <option value={'saturday'}>Sobota</option>
                                    <option value={'sunday'}>Niedziela</option>
                                </select>
                            </div>
                            <div className="form-control w-full max-w-xs">
                                <label className="label">
                                    <span className="label-text">Godzina od</span>
                                </label>
                                <input
                                    className="flex-1 input-bordered select w-full max-w-xs"
                                    type={'time'}
                                    onChange={(e) => {
                                        updateForm('startTime', `${e.currentTarget.value}:00`);
                                    }}
                                />
                            </div>
                            <div className="form-control w-full max-w-xs">
                                <label className="label">
                                    <span className="label-text">Godzina do</span>
                                </label>
                                <input
                                    className="flex-1 input-bordered select w-full max-w-xs"
                                    type={'time'}
                                    onChange={(e) => {
                                        updateForm('endTime', `${e.currentTarget.value}:00`);
                                    }}
                                />
                            </div>
                            <div className="form-control w-full max-w-xs">
                                <label className="label">
                                    <span className="label-text">Gabinet</span>
                                </label>
                                <select name={'office'} className={'flex-1 input-bordered select w-full max-w-xs'}
                                        onChange={(e) => updateForm('office', e.currentTarget.value)}
                                >
                                    <option value={undefined}></option>
                                    {
                                        offices.map((o) => (
                                            <option key={`__${o.id}`} value={`/api/offices/${o.id}`}>{o.name} ({o.description})</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="form-control w-full max-w-xs">
                                <label className="label">
                                    <span className="label-text">&nbsp;</span>
                                </label>
                                <button
                                    disabled={
                                        !formValue || !formValue.startTime || !formValue.endTime
                                        || !formValue.type || !formValue.office
                                    }
                                    className={'btn btn-primary'}
                                >Dodaj</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}