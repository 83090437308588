import React, {ComponentType} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {Dashboard} from "./Dashboard/Dashboard";
import {BookingPages} from "./MainBooking/BookingPages";
import {Login} from "./Login/Login";


const guardCheck = (AppComponent: ComponentType, type: 'MUST_BE_LOGGED' | 'MUST_BE_NOT_LOGGED') => {

    const hasToken = !!localStorage.getItem('token');
    const redirectToLogin = <Navigate to={'/login'}/>;
    const redirectToDashboard = <Navigate to={'/dashboard'}/>;

    switch (type) {
        case "MUST_BE_LOGGED": {
            return hasToken ? <AppComponent/> : redirectToLogin;
        }
        case "MUST_BE_NOT_LOGGED": {
            return !hasToken ? <AppComponent/> : redirectToDashboard;
        }
    }

    return <AppComponent/>
}

function App() {
  return (
      <Routes>
          <Route path="/dashboard/*" element={guardCheck(Dashboard, 'MUST_BE_LOGGED')} />
          <Route path="/login" element={guardCheck(Login, 'MUST_BE_NOT_LOGGED')} />
          <Route path="/*" element={<BookingPages />} />
      </Routes>
  );
}

export default App;
