import {iconStatus, Reservation, translateStatus} from "../../../../models/reservation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export function ReservationStatus(props: { reservation: Reservation }) {
    return (
        <>
            <FontAwesomeIcon icon={iconStatus(props.reservation.status)}/> {translateStatus(props.reservation.status)}
        </>
    )
}