import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faList, faPowerOff, faUsers, faBookOpenReader} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";

export function Navbar() {

    const logout = () => {
        localStorage.removeItem('token');
        window.location.replace('/login');
    }

    return (
        <nav id="header" className="bg-white w-full z-10 top-0 shadow">
            <div className="w-full container mx-auto flex flex-wrap items-center mt-0 pt-3 pb-3 md:pb-0">
                <div className="lg:w-1/2 pl-2 md:pl-0">
                    <span className="text-gray-900 text-base xl:text-xl no-underline hover:no-underline font-bold">
                        Zarządzanie rezerwacjami
                    </span>
                </div>
                <div className="md:w-1/2 w-2/5 pr-0 text-right flex flex-row justify-end">
                        <div className={'flex md:text-2xl text-xs'}>
                            <button className={'btn btn-link btn-xs'} onClick={() => logout()}>
                                <FontAwesomeIcon icon={faPowerOff}/>
                            </button>
                        </div>
                </div>
                <div
                    className="w-full flex-grow lg:flex lg:items-center lg:w-auto lg:block mt-2 lg:mt-0 bg-white"
                    id="nav-content">
                    <ul className="list-reset lg:flex flex-1 items-center lg:px-12 px-4">
                        <li className="lg:mr-6 my-2 md:my-0">
                            <NavLink to={'/dashboard'}
                                     end
                                     className={ ({isActive}) => `${isActive ? 'text-pink-600 border-orange-600 hover:text-gray-900 hover:border-orange-600' : 'hover:text-pink-600 hover:border-orange-600 text-gray-900'} block py-1 md:py-3 pl-1 align-middle no-underline border-b-2 `}>
                                <FontAwesomeIcon icon={faCalendar}/>
                                <span className="pl-1 pb-1 md:pb-0 text-sm">
                                    Kalendarz
                                </span>
                            </NavLink>
                        </li>
                        <li  className="lg:mr-6 my-2 md:my-0">
                            <NavLink to={'/dashboard/list'}
                                     end
                                     className={ ({isActive}) => `${isActive ? 'text-pink-600 border-orange-600 hover:text-gray-900 hover:border-orange-600' : 'hover:text-pink-600 hover:border-orange-600 text-gray-900'} block py-1 md:py-3 pl-1 align-middle no-underline hover:text-gray-900 border-b-2 hover:border-orange-600`}>
                                <FontAwesomeIcon icon={faList}/>
                                <span className="pl-1 pb-1 md:pb-0 text-sm">
                                    Lista rezerwacji
                                </span>
                            </NavLink>
                        </li>
                        <li className="lg:mr-6 my-2 md:my-0">
                            <NavLink to={'/dashboard/workers'}
                                     end
                                     className={ ({isActive}) => `${isActive ? 'text-pink-600 border-orange-600 hover:text-gray-900 hover:border-orange-600' : 'hover:text-pink-600 hover:border-orange-600 text-gray-900'} block py-1 md:py-3 pl-1 align-middle no-underline hover:text-gray-900 border-b-2 hover:border-orange-600`}>
                                <FontAwesomeIcon icon={faUsers}/>
                                <span className="pl-1 pb-1 md:pb-0 text-sm">
                                    Pracownicy
                                </span>
                            </NavLink>
                        </li>
                        {/*<li className="lg:mr-6 my-2 md:my-0">
                            <NavLink to={'/dashboard/bookers'}
                                     end
                                     className={ ({isActive}) => `${isActive ? 'text-pink-600 border-orange-600 hover:text-gray-900 hover:border-orange-600' : 'hover:text-pink-600 hover:border-orange-600 text-gray-900'} block py-1 md:py-3 pl-1 align-middle no-underline hover:text-gray-900 border-b-2 hover:border-orange-600`}>
                                <FontAwesomeIcon icon={faBookOpenReader}/>
                                <span className="pl-1 pb-1 md:pb-0 text-sm">
                                    Klienci
                                </span>
                            </NavLink>
                        </li>*/}
                    </ul>
                </div>
            </div>
        </nav>
    )
}