import {Link, Navigate, useParams} from "react-router-dom";
import {useState} from "react";
import {
    ALLOWED_RESERVATION_STATUS,
    iconStatus,
    Reservation,
    RESERVATION_STATUS,
    translateStatus
} from "../../../models/reservation";
import {updateReservationStatus} from "../../dashboard-api";
import {ReservationPeriod} from "./components/ReservationDate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAt,
    faCalendar,
    faPersonCircleQuestion,
    faPhone,
    faUserCheck,
    faUserTie
} from "@fortawesome/free-solid-svg-icons";
import {ReservationStatus} from "./components/ReservationStatus";
import {BookerHiddenData} from "./components/BookerHiddenData";
import {useReservationFinder} from "../../hooks/useReservationFinder";
import {toast} from "react-toastify";
import {ReservationEditStatusModal} from "../../components/ReservationEditStatusModal";
import {RepeatReservation} from "../ReservationEdit/RepeatReservation";

export function ReservationPreview() {
    const {id} = useParams();

    const [reservation, setReservation] = useState<Reservation>();
    const [ready, isReady] = useState<boolean>(false);

    const [confirmModal, displayConfirmModal] = useState<boolean>(false);
    const [selectedStatus, setSelectedStatus] = useState<RESERVATION_STATUS>();
    const [parentReservationId, setPartentReservationId] = useState<number | string>();


    useReservationFinder(id, ({status, reservation}) => {
        setPartentReservationId(undefined);
        switch (status) {
            case 'ONLOAD': {
                isReady(false);
                break;
            }
            case 'NOT_FOUND': {
                isReady(false);
                break;
            }
            case 'SUCCESS': {
                isReady(true);
                setReservation(reservation);
                if (reservation?.parent) {
                    const [,,,reservationId] = (reservation.parent as string).split('/');
                    setPartentReservationId(reservationId);
                }
                break;
            }
        }
    })

    const updateStatus = (status: RESERVATION_STATUS) => {

        if (reservation) {
            if (reservation.childReservations.length > 0 || reservation.parent) {
                setSelectedStatus(status);
                displayConfirmModal(true);
            } else {
                if (window.confirm(`Czy chcesz zmienić status tej rezerwacji na ${translateStatus(status)}?`)) {
                    isReady(false);
                    updateReservationStatus(`${id}`, status)
                        .then((data) => {
                            isReady(true);
                            toast.success(`Status rezerwacji został zmieniony na ${translateStatus(status)}`);
                            setReservation(data);
                        })
                }
            }
        }
    }


    if (!id) {
        return (
            <Navigate to={'/dashboard'}/>
        )
    }

    return (
        ready && reservation ? (
                <>
                    { selectedStatus && <ReservationEditStatusModal
                        opened={confirmModal}
                        reservation={reservation}
                        selectedStatus={selectedStatus}
                        onClose={(res) =>{
                            setReservation(res);
                            displayConfirmModal(false);
                        }}
                    /> }
                    <div className={'card bg-base-100'}>
                        <div className={'card-body'}>
                            <div className="indicator w-full">
                                <h1 className={'card-title md:mx-auto'}>Informacje o rezerwacji</h1>
                            </div>
                            <div className="card-actions justify-end">
                                {
                                    parentReservationId ?
                                        <Link to={`/dashboard/reservation/${parentReservationId}`} className="mt-1 badge badge-outline">
                                            Przejdź do rezerwacji nadrzędnej
                                        </Link> : <></>
                                }
                                <Link to={`/dashboard/reservation/${reservation.id}/edit`} className="mt-1 badge badge-outline">
                                    Edytuj wizytę
                                </Link>
                                <div className="dropdown">
                                    <label tabIndex={0} className="badge badge-outline">
                                        Zmień status rezerwacji
                                    </label>
                                    <ul tabIndex={0}
                                        className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                                        {
                                            ALLOWED_RESERVATION_STATUS
                                                .filter((status) => status !== reservation?.status)
                                                .map((e) => {
                                                    return (
                                                        <li key={`status_${e}`}>
                                                            <span className={'cursor-pointer'} onClick={() => updateStatus(e)}>
                                                                <FontAwesomeIcon icon={iconStatus(e)}/> {translateStatus(e)}
                                                            </span>
                                                        </li>
                                                    )
                                                })
                                        }
                                    </ul>
                                </div>
                                {/*<button className="badge badge-outline">Anuluj</button>*/}
                            </div>
                            <div className={'container'}>
                                <div className={'divider'}>
                                    <h2 className={'text-lg text-primary'}>
                                        <FontAwesomeIcon icon={faPersonCircleQuestion}/> Status
                                    </h2>
                                </div>
                                <div className={'text-center'}>
                                    <ReservationStatus reservation={reservation}/>
                                </div>
                                <div className={'divider'}>
                                    <h2 className={'text-lg text-primary'}>
                                        <FontAwesomeIcon icon={faCalendar}/> Data i godzina
                                    </h2>
                                </div>
                                <h1 className={'text-lg text-center'}>
                                    <ReservationPeriod
                                        dateFrom={reservation?.startDate}
                                        dateTo={reservation?.endDate}/>
                                </h1>
                                <div className={'divider'}>
                                    <h2 className={'text-lg text-primary'}>
                                        <FontAwesomeIcon icon={faUserTie}/> Pracownik
                                    </h2>
                                </div>
                                <div className="flex items-center justify-center">
                                    <div
                                        className="flex flex-col items-center w-full max-w-xs p-4 bg-white rounded-3xl md:flex-row">
                                        <img
                                            className="flex-col w-24 rounded-full ring ring-offset-2 "
                                            style={{borderColor: reservation.worker.color}}
                                            src={reservation.worker.picUrl}
                                            alt={reservation.worker.name}
                                        />
                                        <div className="flex flex-col space-y-4 mx-5">
                                            <div className="flex flex-col items-center md:items-start">
                                                <h2 className="text-xl font-medium">
                                                    {reservation.worker.name}
                                                </h2>
                                                <p className="text-base font-medium text-gray-400">
                                                    {reservation.worker.description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'divider'}>
                                    <h2 className={'text-lg text-primary'}>
                                        <FontAwesomeIcon icon={faUserCheck}/> Klient
                                    </h2>
                                </div>
                                <div className={'flex flex-col text-center'}>
                                    <div>Pan/Pani</div>
                                    <span className={'font-semibold'}>
                                        {reservation.booker.firstName} {reservation.booker.lastName.charAt(0)}.
                                    </span>
                                    <em className={'py-2'}>{reservation.additionalInfo || 'Nie podano dodatkowych informacji'}</em>
                                </div>
                                <div className={'flex'}>
                                    <div className={' mx-auto'}>
                                        <BookerHiddenData
                                            regexp={/(\w)(\w)(?=.*@)/g}
                                            data={reservation.booker.email}
                                            icon={faAt}/>
                                    </div>
                                    <div className={'mx-auto'}>
                                        <BookerHiddenData
                                            regexp={/(\d){6}$/g}
                                            data={reservation.booker.phoneNumber}
                                            icon={faPhone}/>
                                    </div>
                                </div>
                                <div className={'flex flex-col'}>
                                    {reservation.childReservations.length ?
                                        <RepeatReservation allowToAddRepeat={false} reservations={reservation.childReservations}/> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) :
            <>Czekaj...</>
    )
}