import {WorkerSelectorField} from "../../../../MainBooking/components/WorkerSelector";
import {AnyOfReservation, Reservation} from "../../../../models/reservation";
import {OfficeSelector} from "../../../components/OfficeSelector";
import {useFormikContext} from "formik";
import {ALLOWED_DATES_TYPE} from "../../../consts";
import {useEffect} from "react";
import moment, {Moment} from "moment/moment";
import {OfficeValidator} from "../../../components/OfficeValidator";
import {ReservationAvailableText} from "./ReservationAvailableText";
import {getWorker} from "../../../../MainBooking/booking-api";

function UpdateEndDate({startDate, isTouched, worker}: {startDate: ALLOWED_DATES_TYPE, isTouched: boolean, worker: number}) {

    const {setFieldValue} = useFormikContext<AnyOfReservation>();

    useEffect(() => {
        if (startDate && worker && !isTouched) {
            getWorker(worker)
                .then((w) => {
                    const endDate:Moment = moment(startDate).clone();
                    endDate.add(w.workingTime, 'minutes');
                    setFieldValue('endDate', endDate.format('YYYY-MM-DD HH:mm:ss'));
                })

        }

    }, [startDate, isTouched, worker])

    return null;
}


export function ReservationInformationFields() {

    const {values, touched, handleChange} = useFormikContext<AnyOfReservation>();

    return (
        <>
            <div className={'form-control w-full max-w-xs'}>
                <label className="label">
                    <span className="label-text">Pracownik</span>
                </label>
                <WorkerSelectorField
                    name={'worker'}
                    value={values.worker}
                    onChange={handleChange}/>
            </div>
            <div className="form-control w-full max-w-xs">
                <label className="label">
                    <span className="label-text">Wizyta OD</span>
                </label>
                <input
                    type="datetime-local"
                    name="startDate"
                    onChange={handleChange}
                    value={values.startDate}
                    className={`input input-bordered w-full max-w-xs`}/>
            </div>
            <div className="form-control w-full max-w-xs">
                <label className="label">
                    <span className="label-text">Wizyta DO</span>
                </label>
                <UpdateEndDate
                    startDate={values.startDate}
                    isTouched={touched.endDate as boolean}
                    worker={values.worker}/>
                <input
                    type="datetime-local"
                    name="endDate"
                    onChange={handleChange}
                    value={values.endDate}
                    className={`input input-bordered w-full max-w-xs`}/>
            </div>
            <div className={'form-control w-full max-w-xs'}>
                <OfficeSelector
                    worker={values.worker}
                    startDate={values.startDate}
                    endDate={values.endDate}
                />
                <OfficeValidator
                    startDate={values.startDate}
                    endDate={values.endDate}
                    worker={values.worker}
                    office={values.office}
                    reservation={{id:values.id} as Reservation}
                >
                    {
                        ({state}) => (
                            <ReservationAvailableText status={state}/>
                        )
                    }
                </OfficeValidator>

            </div>
            <div className={'form-control w-full max-w-xs'}>
                <label className="label">
                    <span className="label-text">Dodatkowe informacje</span>
                </label>
                <textarea
                    name={'additionalInfo'}
                    onChange={handleChange}
                    value={values.additionalInfo}
                    className={`textarea textarea-bordered h-24`}
                ></textarea>
            </div>
        </>
    )
}