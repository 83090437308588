import {OfficeValidatorState} from "../../../components/OfficeValidator";

interface ReservationAvailableTextProps {
    status: OfficeValidatorState
}

export function ReservationAvailableText({status}: ReservationAvailableTextProps) {

    switch (status) {
        case "CHECKING": {
            return <span className={'text-gray-500'}>Sprawdzam</span>;
        }
        case "NOT_ALLOWED": {
            return <span className={'text-red-500'}>
                Gabinet w tych godzinach jest już zarezerwowany
            </span>;
        }
        default: {
            return null;
        }
    }
}