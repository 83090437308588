import {ReservationForm, ReservationFormWithRepeat} from "./ReservationForm";
import {useState} from "react";
import {createReservationRequest} from "../../requests/createReservationRequest";
import {toast} from "react-toastify";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Reservation} from "../../../models/reservation";

export function CreateReservation() {

    const [onRequest, setOnRequest] = useState<boolean>(false);
    const navigate = useNavigate();

    const [params] = useSearchParams();

    const reservationData = {
        startDate: params.has('date') ? params.get('date') : '',
    } as Reservation;

    const createReservation = (values: ReservationFormWithRepeat) => {
        setOnRequest(true);

        createReservationRequest(values)
            .then((data) => {
                setOnRequest(false);
                if (!data.id) {
                    Object.keys(data).forEach((key) => {
                        // @ts-ignore
                        data[key].forEach((v) => {
                            toast.error(v);
                        })
                    });
                } else {
                    toast.success("Rezerwacja została utworzona");
                    navigate(`/dashboard/reservation/${data.id}`, {replace: true});

                }
            })
    }

    return <>
        <ReservationForm
            reservation={reservationData}
            blockReservation={onRequest}
            onSubmit={createReservation}/>
    </>
}