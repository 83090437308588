import {Formik} from "formik";
import {translateStatus} from "../../../../models/reservation";
import {WorkerSelectorField} from "../../../../MainBooking/components/WorkerSelector";

export interface PaginationData {
    page: number;
    itemsPerPage?: number;
}

export interface SearchData extends PaginationData {
    status?: string | null;
    worker?: number;
    dateFrom?: string | Date | null;
    dateTo?: string | Date | null;
}

const searchInitialValues: SearchData = {
    status: null,
    worker: 0,
    page: 1,
    itemsPerPage: 5,
}

export function FiltersList({onSearch}: { onSearch: (data: SearchData) => void }) {

    const submitForm = (values: SearchData) => {
        onSearch(values);
    }

    return (
        <>
            <div className="card bg-base-100 shadow-xl">
                <Formik initialValues={searchInitialValues} onSubmit={submitForm}>
                    {({values, handleChange, handleSubmit, setFieldValue}) => (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <h2 className="card-title">Wyszukiwarka</h2>
                                    <div className={'grid grid-cols-3 lg:grid-cols-2 md:grid-cols-1'}>
                                        <div className="form-control w-full max-w-xs">
                                            <label className="label">
                                                <span className="label-text">Status</span>
                                            </label>
                                            <select
                                                name={'status'}
                                                value={values.status || '' as string}
                                                onChange={handleChange}
                                                className={`input input-bordered w-full max-w-xs`}
                                            >
                                                <option></option>
                                                <option value={'CREATED'}>{translateStatus('CREATED')}</option>
                                                <option value={'CANCELLED'}>{translateStatus('CANCELLED')}</option>
                                                <option value={'CONFIRMED'}>{translateStatus('CONFIRMED')}</option>
                                                <option
                                                    value={'VERIFICATION'}>{translateStatus('VERIFICATION')}</option>
                                            </select>
                                        </div>
                                        <div className="form-control w-full max-w-xs">
                                            <label className="label">
                                                <span className="label-text">Pracownik</span>
                                            </label>
                                            <WorkerSelectorField
                                                name={'worker'}
                                                canSelectEmpty={true}
                                                onChange={handleChange}
                                                value={values.worker}/>
                                        </div>
                                        <div className="form-control w-full max-w-xs">
                                            <label className="label">
                                                <span className="label-text">Data OD</span>
                                            </label>
                                            <input
                                                className={`input input-bordered w-full max-w-xs`}
                                                type={'datetime-local'}
                                                onChange={(event) =>
                                                    setFieldValue('dateFrom', event.currentTarget.value)}
                                            />
                                        </div>
                                        <div className="form-control w-full max-w-xs">
                                            <label className="label">
                                                <span className="label-text">Data Do</span>
                                            </label>
                                            <input
                                                className={`input input-bordered w-full max-w-xs`}
                                                type={'datetime-local'}
                                                onChange={(event) =>
                                                    setFieldValue('dateTo', event.currentTarget.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="card-actions justify-end">
                                        <button className="btn btn-primary" type={'submit'}>
                                            Szukaj
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </>
                    )}
                </Formik>
            </div>
        </>
    )
}