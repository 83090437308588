import FullCalendar, {EventInput} from "@fullcalendar/react";
import timeGridPlugin from '@fullcalendar/timegrid';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {CalendarReservationModal} from "./CalendarReservationModal";
import {Reservation} from "../../../models/reservation";

export type FullCalendarEventFun = (
    info: {start: Date; end:Date},
    successCallback: (events: EventInput[]) => void,
    failureCallback: (err: any) => void
) => void;

export function Calendar({eventsData$}: {eventsData$: FullCalendarEventFun}) {

    const navigation = useNavigate();

    const [ready, isReady] = useState<boolean>(false);
    const [selectedReservation, setSelectedReservation] = useState<Reservation>();

    useEffect(() => {
        isReady(true);
    }, []);

    return (
        <>

            { selectedReservation ? <CalendarReservationModal
                reservation={selectedReservation}
                onClose={() => setSelectedReservation(undefined)}/> : null
            }
            {ready ? (
                <FullCalendar
                    plugins={[timeGridPlugin]}
                    initialView="timeGridWeek"
                    firstDay={1}
                    locale={'pl'}
                    events={eventsData$}
                    eventClick={(e) => {
                        const {status, reservation} = e.event.extendedProps;
                        if (status === 'ALREADY_CREATED') {
                            setSelectedReservation(reservation)
                        } else {
                            const {start} = e.event;
                            const eventDate = moment(start).format('YYYY-MM-DD HH:mm:ss');
                            navigation(`/dashboard/reservation/create?date=${eventDate}`, {replace: true});
                        }
                    }}
                />) : (<h1>Czekaj...</h1>)
            }
        </>
    )
}