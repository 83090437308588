import {iconStatus, Reservation, RESERVATION_STATUS, translateStatus} from "../../models/reservation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLayerGroup, faPuzzlePiece, faClockRotateLeft} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import {CHANGE_STATUS_TYPE, changeReservationStatusRequest} from "../requests/changeReservationStatusRequest";

interface ReservationEditStatusModalProps {
    opened: boolean;
    selectedStatus: RESERVATION_STATUS;
    reservation: Reservation;
    onClose: (res: Reservation) => void;
}


export function ReservationEditStatusModal({
                                               opened,
                                               reservation,
                                               selectedStatus,
                                               onClose
                                           }: ReservationEditStatusModalProps) {

    const updateReservations = (updateType: CHANGE_STATUS_TYPE) => {
        changeReservationStatusRequest(
            reservation,
            selectedStatus,
            updateType
        ).then(() => {
            toast.success('Statusy zostały zaktualizowane');
            onClose && onClose({
                ...reservation,
                status: selectedStatus
            });
        });

    }

    return (
        <>
            <input type="checkbox" id={'remove-modal'} className="modal-toggle" checked={opened} readOnly={true}/>
            <div className="modal">
                <div className="modal-box">
                    <label htmlFor={'remove-modal'}
                           onClick={() => onClose && onClose({...reservation})}
                           className="btn btn-xs btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="font-bold text-lg">
                        <FontAwesomeIcon
                            icon={iconStatus(selectedStatus)}
                            className={'px-1'}/> Zmiana statusu na {translateStatus(selectedStatus)}
                    </h3>
                    <p className={'py-4'}>
                        Której rezerwacji chcesz zmienić status?
                    </p>
                    <div className={'grid grid-cols-2 gap-4'}>
                        <button className={'flex-1 btn btn-info flex flex-col'} onClick={() => updateReservations('ALL')}>
                            <FontAwesomeIcon className={'my-1'} icon={faLayerGroup}/>
                            <small>Wszystkich</small>
                        </button>
                        <button className={'flex-1 btn btn-warning flex flex-col'} onClick={() => updateReservations('ONLY_THIS')}>
                            <FontAwesomeIcon className={'my-1'} icon={faPuzzlePiece}/>
                            <small>Tylko tego</small>
                        </button>
                        <button className={'flex-1 btn btn-primary flex flex-col'} onClick={() => updateReservations('FROM_TODAY')}>
                            <FontAwesomeIcon className={'my-1'}  icon={faClockRotateLeft}/>
                            <small>Od dziś</small>
                        </button>
                        <button className={'flex-1 btn btn-accent flex flex-col'} onClick={() => updateReservations('FROM_CURRENT')}>
                            <FontAwesomeIcon className={'my-1'}  icon={faClockRotateLeft}/>
                            <small>Przyszłych</small>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}