import {Worker} from "../../models/worker";
import moment, {Moment} from "moment";
import {useContext, useEffect, useState} from "react";
import {Office} from "../../models/office";
import {checkReservation, getSelectedOffice} from "../../MainBooking/booking-api";
import {useFormikContext} from "formik";
import {AnyOfReservation, Reservation} from "../../models/reservation";
import {ReservationContext, ReservationContextData} from "../contexts/ReservationContext";
import {isValidDate} from "../consts";


export function OfficeSelector({
                                   worker,
                                   startDate,
                                   endDate
                               }: { worker?: number | string | Worker, startDate?: string | Moment | Date, endDate?: string | Moment | Date }) {

    const [office, selectOffice] = useState<Office>();
    const [onLoad, setOnLoad] = useState<boolean>();
    const [displayError, setDisplayError] = useState<boolean>(false);

    const formikRef = useFormikContext<AnyOfReservation>();

    const {reservation, updateReservation} = useContext<ReservationContextData>(ReservationContext);

    const isValid = () => {
        return worker && startDate && endDate && isValidDate(startDate) && isValidDate(endDate);
    }

    useEffect(() => {
        if (isValid()) {
            setOnLoad(true);
            selectOffice(undefined);
            getSelectedOffice((worker && typeof worker === 'object') ? worker!.id : worker!, startDate!)
                .then((r) => {
                    setOnLoad(false);
                    if (r && r.length) {
                        selectOffice(r[0].office);
                        if (formikRef) {
                            formikRef.setFieldValue('office', r[0].office);
                        }
                    }
                })
            ;
        }
    }, [worker, startDate, endDate]);

    if (!isValid()) {
        return <span className={'text-yellow-600'}>Nie wybrano danych... </span>;
    }

    if (onLoad) {
        return <span className={'text-gray-500'}>Sprawdzam</span>;
    }

    if (!office) {
        return <span className={'text-red-500'}>Tego dnia pracownik nie pracuje</span>;
    }

    return <>
        <span className={'text-green-600'}>Wybrany gabinet: {office.name}</span>
    </>
        ;
}
