import {Worker} from "../../models/worker";
import {useEffect, useState} from "react";
import {getWorkers} from "../booking-api";

export function WorkerSelectorField(props: any) {

    const [workers, setWorkers] = useState<Worker[]>([]);

    const canSelectEmpty = props && props.canSelectEmpty
    useEffect(() => {
        getWorkers()
            .then((data) => setWorkers(data))
    }, [])

    return (
        <>
            <select
                className={'input input-bordered w-full'}
                {...(() => {
                    const copy = Object.assign({}, props);
                    delete copy.canSelectEmpty;
                    return copy;
                })()}
            >
                <option key={`worker_none`} disabled={!canSelectEmpty} value={0}> - Wybierz -</option>
                {
                    workers.map((w, index) => (
                        <option key={`worker_${w.id}_${index}`} value={w.id}>
                            {w.name}
                        </option>
                    ))
                }
            </select>
        </>
    )
}