import {PaginationResponse} from "./models/paginationResponse";

export function makeRequest<T>(
    url: string, method: 'GET' | 'POST' | 'PATCH' | 'DELETE',
    data: any = null, headers: {[k: string]: string} = {},
    ignoreToken = false
): Promise<T> {


        return makeFetch(url, method, data, headers, ignoreToken)
            .then((response) => {
                if (response.status === 204) {
                    return response.statusText;
                }
                    return response.json()

        })
        // .then((e) => {
        //     console.log('end fetch ', e, url);
        //     return e;
        // })
    ;
}

export function makePaginationRequest<T>(
    url: string, method: 'GET' | 'POST' | 'PATCH' | 'DELETE',
    data: any = null, headers: {[k: string]: string} = {},
    ignoreToken = false
): Promise<PaginationResponse<T>> {

    const pagination = new PaginationResponse<T>();

    return makeFetch(url, method, data, headers, ignoreToken)
        .then((response) => {
            pagination.total = Number(response.headers.get('App-Total-Items'));
            pagination.itemsPerPage = Number(response.headers.get('App-Items-Per-Page'));
            pagination.currentPage = Number(response.headers.get('App-Current-Page'));
            pagination.totalPages = Number(response.headers.get('App-Total-Pages'));
            return response.json();
        })
        .then((items: T[]) => {
            pagination.data = items;
            return pagination;
        })

}

export function makeFetch(
    url: string,
    method: 'GET' | 'POST' | 'PATCH' | 'DELETE',
    data: any = null,
    headers: {[k: string]: string} = {},
    ignoreToken = false
): Promise<Response> {
    let body = null;
    let requestHeaders: {[k: string]: string} = {
        "accept": "application/json",
        ...headers
    };

    if (data) {
        body = JSON.stringify(data);
        if (method === "PATCH") {
            requestHeaders["Content-Type"] = "application/merge-patch+json";
        } else {
            requestHeaders["Content-Type"] = "application/json";
        }
    }

    if (localStorage.getItem('token') && !ignoreToken) {
        requestHeaders['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    }

    return fetch(url, {
        method,
        body,
        headers: requestHeaders
    })
        .then((e) => {
            if (!e.ok && e.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(`/login`);
                throw new Error('Invalid token');
            }

            return e;

        })
}