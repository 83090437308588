import {ReservationFormWithRepeat} from "../pages/NewReservationForm/ReservationForm";
import {makeRequest} from "../../makeRequest";
import {API_URL} from "../shared";
import {dateToApi} from "../../utils/formatDate";

export function createReservationRequest(data: ReservationFormWithRepeat) {

    const requestData = {
        ...data,
        office: `/api/offices/${data.office.id}`,
        worker: `/api/workers/${data.worker}`,
        startDate: dateToApi(data.startDate),
        endDate: dateToApi(data.endDate),
        repeatVisits: data.repeatVisits.map((e) => {
            return {
                startDate: dateToApi(e.startDate),
                endDate: dateToApi(e.endDate),
            }
        })
    }

    return makeRequest<{id: number | string} | {[k: string]: string}>(
        `${API_URL}/dashboard/reservation`,
        "POST",
        requestData
    )

}