import './Spinner.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

export function Spinner(props: {
    display: boolean
    children: React.ReactNode
}) {
    return (
        <div className={'spinnerHolder'}>
            {(props.display) && (
                <div className={'spinner'}>
                    <FontAwesomeIcon icon={faSpinner} size={'10x'}  pulse={true}/>
                </div>)
            }
            {props.children}
        </div>
    );
}