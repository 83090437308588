import {ChangeEventHandler, useEffect, useState} from "react";
import {getAvailableHours} from "../booking-api";
import moment from "moment/moment";
import {useFormikContext} from "formik";

export interface SelectReservationDataProps {
    selectedWorker?: number;
    selectedDay?: string | Date;
    officeName: string;
    timeName: string;
}

export function SelectReservationTime({selectedWorker, selectedDay, officeName, timeName}: SelectReservationDataProps) {

    const [availableHours, setAvailableHours] = useState<{ time: string, officeId: number }[]>([]);

    const {setFieldValue, handleChange} = useFormikContext();

    useEffect(() => {
        if (selectedDay && selectedWorker) {
            getAvailableHours(selectedWorker, moment(selectedDay).format('YYYY-MM-DD'))
                .then((e) => {

                    return e.map((v) => ({
                        time: `${v.from} - ${v.to}`,
                        officeId: v.officeId
                    }))
                })
                .then((items) => setAvailableHours(items));
        }
    }, [selectedWorker, selectedDay]);

    if (!availableHours.length) {
        return (
            <div className={'text-center'}>
                <div>Brak dostępnych godzin.</div>
                <div>Wybierz innego terapeutę lub zmień datę wizyty.</div>
            </div>
        )
    }

    return (
        <>
            <div className="grid gap-1 grid-cols-2 md:grid-cols-4 lg:grid-cols-2">
                {
                    availableHours.map((e) => {
                        return (
                            <div className="form-control"
                                 key={`__time_${e.time}`}>
                                <label className="label cursor-pointer">
                                    <input
                                        type="radio"
                                        name={timeName}
                                        onChange={handleChange}
                                        onClick={(ev) => setFieldValue(officeName, e.officeId)}
                                        className="radio"
                                        value={e.time}/>
                                    <span className="label-text my-2">{e.time}</span>
                                </label>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}