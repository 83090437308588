import {Formik} from "formik";
import {login} from "./login-api";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useState} from "react";
import {Spinner} from "../Shared/components/Spinner";

interface LoginFormData {
    username: string;
    password: string;
}

const loginInitialValues: LoginFormData = {
    username: '',
    password: '',
}

export function Login() {

    const [onRequest, isOnRequest] = useState<boolean>(false);

    const onSubmit = (v: LoginFormData) => {
        isOnRequest(true);
        login(v.username, v.password)
            .then((data) => {
                localStorage.setItem('token', data.token);
                window.location.reload();
            })
            .catch(() => {
                toast.error('Błędne dane logowania')
            })
            .finally(() => {
                isOnRequest(false);
            })
    }

    return (
        <Formik<LoginFormData>
            initialValues={loginInitialValues}
            onSubmit={(v) => onSubmit(v)}>
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue
              }) => (
                <>
                    <Spinner display={onRequest}>
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                        <form onSubmit={handleSubmit}>
                            <div
                                className="bg-white sm:bg-gray-200 min-h-screen w-screen flex flex-col justify-center items-center">
                                <div
                                    className="bg-white shadow-none sm:shadow-lg px-8 sm:px-12 w-full xs:w-full sm:w-8/12 md:w-7/12 lg:w-7/12 xl:w-2/6 h-screen sm:h-auto py-8">
                                    <div className="text-center w-full font-bold text-3xl text-gray-600 p-4">
                                        Logowanie
                                    </div>
                                    <div
                                        className="w-full bg-gray-200 my-3"
                                    ></div>
                                    <div className="flex flex-col gap-4 px-0 py-4">
                                        <div>
                                            <label className="text-gray-700">Nazwa użytkownika</label>
                                            <input

                                                name={'username'}
                                                onChange={handleChange}
                                                value={values.username}
                                                className="py-2 pl-10 border border-gray-200 w-full"
                                                placeholder="Nazwa użytkownika"
                                                type="text"
                                            />
                                        </div>
                                        <div>
                                            <label className="text-gray-700">Hasło</label>
                                            <input
                                                name={'password'}
                                                onChange={handleChange}
                                                value={values.password}
                                                className="py-2 pl-10 border border-gray-200 w-full"
                                                placeholder="Hasło"
                                                type="password"
                                            />
                                        </div>
                                        <div className="w-full flex flex-row justify-center items-center ">
                                            <button
                                                className="border p-3 border-indigo-500 hover:bg-indigo-500 hover:text-white duration-100 ease-in-out w-6/12 text-indigo-500 flex flex-row justify-center items-center gap-1"
                                                type="submit"
                                            >
                                                Zaloguj
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-4 text-sm">dbooking-system</div>
                            </div>
                        </form>
                    </Spinner>
                </>
            )}
        </Formik>
    );
}