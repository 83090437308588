export const DateFieldInput = (props:any) => (
    <input
        {...props}
        className={`${props.className} input input-bordered w-full max-w-xs`}/>
)

export const AnotherMuiDateFieldInput = (props: any) => (
    <input
        {...props}
        className={`${props.className} input input-bordered w-full max-w-xs`}/>
)

export const API_URL = process.env.REACT_APP_API_URL;
