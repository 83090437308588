import {
    REPEAT_DAY, REPEAT_MONTH,
    REPEAT_TWO_WEEKS, REPEAT_WEEK,
    RepeatReservationValues
} from "../../../components/RepeatReservationForm/RepeatReservationForm";
import moment from "moment/moment";

export function generateRepeatDates(values: RepeatReservationValues) {

    const dates = [];

    for (let i = 0; i < values.repeatNum; i++) {

        let newStartDate = moment(values.startDate).clone();

        switch (values.repeatEvery) {
            case REPEAT_DAY: {
                newStartDate = newStartDate
                    .add(i + 1, 'days');
                break;
            }
            case REPEAT_TWO_WEEKS:
            case REPEAT_WEEK: {
                newStartDate = newStartDate
                    .add(
                        (i + 1) * (values.repeatEvery === REPEAT_TWO_WEEKS ? 2 : 1),
                        'weeks');
                break;
            }
            case REPEAT_MONTH: {
                newStartDate = newStartDate
                    .add((i + 1), 'months');
                break;
            }
        }
        dates.push(newStartDate);
    }

    return dates;
}