import {useEffect, useState} from "react";
import {Worker} from "../../models/worker";
import {getWorker} from "../booking-api";

export function WorkerInformation({workerId}: {workerId: number | undefined}) {

    const [worker, setWorker] = useState<Worker>();

    useEffect(() => {
        if (workerId && !isNaN(workerId)) {
            getWorker(workerId)
                .then((w) => setWorker(w));
        }
    }, [workerId]);

    if (!worker) {
        return <></>;
    }

    return (
        <div className="flex items-center px-4 py-3 bg-white rounded my-2">
            <img className="h-8 w-8 rounded-full object-cover mx-1"
                 src={worker.picUrl}
                 alt="avatar"
                 style={{borderColor: worker.color, borderStyle: 'solid', borderWidth: '1px'}}
            />
            <div className="text-gray-600 text-sm mx-2">
                <span className="font-bold">{worker.name}</span>
                <div>{worker.description && (<small>{worker.description}</small>)}</div>
            </div>
        </div>
    )
}