import {BookersFilter} from "./BookersFilter";
import {useState} from "react";
import {PaginationResponse} from "../../../models/paginationResponse";
import {Booker} from "../../../models/booker";
import {searchBookers} from "../../dashboard-api";

interface BookerFinderProps {
    children: (onRequest: boolean, data: PaginationResponse<Booker> | undefined) => React.ReactNode
}

export function BookerFinder({children}: BookerFinderProps) {

    const [bookers, setBookers] = useState<PaginationResponse<Booker>>();
    const [onRequest, setOnRequest] = useState<boolean>(false);

    const findBookers = async (v?: string) => {
        setOnRequest(true);
        const data = await searchBookers(v);
        setBookers(data);
        setOnRequest(false);
    }


    return <>
        <BookersFilter
            onSearch={(value?: string) => findBookers(value)}/>
        {children(onRequest, bookers)}
    </>
}