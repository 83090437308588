import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getBooking} from "../../dashboard-api";
import {Reservation} from "../../../models/reservation";
import {ReservationForm, ReservationFormWithRepeat} from "./ReservationForm";
import {updateReservationRequest} from "../../requests/updateReservationRequest";
import {toast} from "react-toastify";

export function EditReservation() {

    const [reservation, setReservation] = useState<Reservation>();
    const [onRequest, setOnRequest] = useState<boolean>(false);

    const {id} = useParams();

    useEffect(() => {
        getBooking(id!)
            .then((reservation) => {
                setReservation(reservation);
            })
    }, [id]);

    const updateReservationData = (r: ReservationFormWithRepeat) => {
        setOnRequest(true);
        updateReservationRequest(reservation!.id, r)
            .then((data) => {
                setOnRequest(false);
                if (!data.id) {
                    Object.keys(data).forEach((key) => {
                        // @ts-ignore
                        data[key].forEach((v) => {
                            toast.error(v);
                        })
                    });
                } else {
                    toast.success("Rezerwacja została zaktualizowana");
                }
            })
    }

    return (
        <>
            {reservation &&
                <ReservationForm
                    blockReservation={onRequest}
                    onSubmit={updateReservationData}
                    reservation={reservation}/>
            }
        </>
    )


}