import {BookingFormData} from "./BookingForm";
import {Worker} from "../models/worker";
import {makeFetch, makeRequest} from "../makeRequest";
import moment, {Moment} from 'moment';
import {WorkerAvailabilities} from "../models/worker-availabilities";
import {Office} from "../models/office";
import {Reservation} from "../models/reservation";
import {ALLOWED_DATES_TYPE} from "../Dashboard/consts";

const API_URL = process.env.REACT_APP_API_URL;

type GET_WORKERS_PARAMETERS = number | {urlName: string};

interface BookingStatus {
    ok?: boolean;
    reservationNumber?: string;
    validationMessages?: {[k: string]: string[]};
}
export function getWorker(id: string | number): Promise<Worker> {
    return makeRequest<Worker>(`${API_URL}/workers/${id}`, "GET", null, {}, true)
}
export function getOffices(): Promise<Office[]> {
    return makeRequest<Office[]>(`${API_URL}/offices`, "GET", null, {}, false)
}

export function getWorkerAvailabilitiesByWorker(workerId: string | number) {
    return makeRequest<WorkerAvailabilities[]>(
        `${API_URL}/worker_availabilities?worker.id=${workerId}`,
        "GET",
        null,
        {}
    );
}

export function getWorkers(params?: GET_WORKERS_PARAMETERS): Promise<Worker[]> {

    let url = `${API_URL}/workers`

    if (params) {
        if (typeof params === 'number') {
            url = `${url}/${params}`
        } else {
            const urlParams = new URLSearchParams(params);
            url = `${url}?${urlParams.toString()}&active=yes`;
        }
    } else {
        url = `${url}?active=yes`;
    }

    return makeRequest<Worker[]>(url, "GET", null, {}, true)
}

export function bookReservation(data: BookingFormData): Promise<BookingStatus> {

    const status: BookingStatus = {};

    return makeFetch(
        `${API_URL}/make-reservation`,
        "POST",
        data,
        {},
        true
    )
        .then((response) => {
            if (response.ok) {
                status.ok = true;
                return response.json();
            }
            status.ok = false;
            if (response.status === 400) {
                return response.json();
            }
            throw new Error('Invalid response');
        })
        .then((json) => {
            status.ok ? status.reservationNumber = json.reservationId : status.validationMessages = json;
            return status;
        })
}

export function getAvailableHours(workerId: string | number, date: string): Promise<{from: string, to: string, officeId: number}[]> {
    const params = new URLSearchParams({
        date
    });
    return makeRequest<{from: string, to: string, officeId: number}[]>(
        `${API_URL}/get-available-hours/${workerId}?${params.toString()}`,
        "GET",
        null,
        {},
        true
    );
}

export function getSelectedOffice(workerId: string | number, date: string | Moment | Date): Promise<WorkerAvailabilities[]> {

    const tmpMoment = moment(date);
    tmpMoment.locale('en');

    const type = tmpMoment.format('dddd').toLowerCase();

    const params = new URLSearchParams({
        'worker.id': `${workerId}`,
        type,
    });

    return makeRequest<WorkerAvailabilities[]>(
        `${API_URL}/worker_availabilities?${params.toString()}`,
        "GET",
        null,
        {}
    );
}

export function checkReservation(
    office: Office,
    startDate: ALLOWED_DATES_TYPE,
    endDate: ALLOWED_DATES_TYPE,
    ignoreReservation?: Reservation | null
) {

    let params = {
        dateFrom: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        dateTo: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        ignoreId: (ignoreReservation && ignoreReservation.id) ? ignoreReservation.id : undefined
    }

    // @ts-ignore
    const queryParam  = new URLSearchParams(params);

    return makeRequest<{ available : boolean } >(
        `${API_URL}/check-available-reservation/${office.id}?${queryParam.toString()}`,
        "GET",
        null,
        {}
    );
}