import {PaginationResponse} from "../../../../models/paginationResponse";

const MAX_PAGES_ON_PAGE = 4;
const PAGES_BETWEEN_CURRENT = Math.floor(MAX_PAGES_ON_PAGE / 2);

export function Pagination(params: { pagination: PaginationResponse<unknown>; maxDisplayedPages: number; onChangePage: (n: number) => void }) {


    const {currentPage, totalPages} = params.pagination;
    let pages = [];
    let startPage = 1;
    let endPage = 1;

    startPage =
        currentPage + PAGES_BETWEEN_CURRENT >= totalPages ?
            (totalPages - MAX_PAGES_ON_PAGE) :
            ((currentPage - PAGES_BETWEEN_CURRENT) <= 1 ? 1 : currentPage - PAGES_BETWEEN_CURRENT)
    ;
    endPage = startPage + MAX_PAGES_ON_PAGE >= totalPages ? totalPages : startPage + MAX_PAGES_ON_PAGE;

    for (let i = startPage; i <= endPage; i++) {
        if (i > 0) {
            pages.push(i);
        }
    }

    const changePage = (page: number) => {
        params.onChangePage && params.onChangePage(page)
    }


    return (
        <>
            <div className={'flex flex-col w-full text-center'}>
                <div className={'flex mx-auto'}>
                    <button
                        disabled={currentPage === 1}
                        onClick={() => changePage(currentPage - 1)}
                        className={`btn mx-2`}>Cofnij</button>
                    <div className="btn-group">
                        {
                            pages
                                .map((e) => {
                                    return (
                                        <button
                                            onClick={() => changePage(e)}
                                            key={`pagin_${e}`}
                                            className={`btn ${e === currentPage && 'btn-active'}`}>{e}</button>
                                    )
                                })
                        }
                    </div>
                    <button
                        disabled={currentPage === totalPages}
                        onClick={() => changePage(currentPage + 1)}
                        className={`btn mx-2`}>Dalej</button>
                </div>
                <div className={'flex text-center justify-center py-1'}>
                    <div className={'w-full'}>strona {currentPage}/{totalPages}</div>
                </div>
            </div>
        </>
    );
}