import {useEffect} from "react";
import {Reservation} from "../../models/reservation";
import {getBooking} from "../dashboard-api";

interface UseReservationFinderCallbackProps {
    status: 'ONLOAD' | 'NOT_FOUND' | 'SUCCESS';
    reservation?: Reservation;
}

export function useReservationFinder(reservationId: number | string | undefined, callback: (data: UseReservationFinderCallbackProps) => void) {

    useEffect(() => {
        callback({status: 'ONLOAD'});
        reservationId ? getBooking(reservationId)
            .then(reservation => callback({ status: 'SUCCESS', reservation}))
            .catch(() => {
                callback({status: 'NOT_FOUND'})
            }) : callback({status: 'NOT_FOUND'});
    }, [reservationId]);
}