import {Moment} from "moment";
import moment from "moment/moment";

export type ALLOWED_DATES_TYPE = string | Date | Moment | undefined;


export function isValidDate(d: ALLOWED_DATES_TYPE) {
    return moment(d).isValid();
}

