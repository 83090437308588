import {useEffect, useState} from "react";
import {Spinner} from "../../../Shared/components/Spinner";
import {getBookings} from "../../dashboard-api";
import {iconStatus, Reservation, translateStatus} from "../../../models/reservation";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass, faPenToSquare, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {FiltersList, SearchData} from "./Filters/FiltersList";
import {Pagination} from "./Pagination/Pagination";
import {PaginationResponse} from "../../../models/paginationResponse";
import {formatDate} from "../../../utils/formatDate";


function searchRequest(data?: SearchData) {

    const queryParams: { [k: string]: string } = { };

    if (data) {
        data.itemsPerPage && (queryParams.itemsPerPage = `${data.itemsPerPage}`);
        data.page && (queryParams.page = `${data.page}`);

        data.status && (queryParams.status = data.status);
        data.worker && data.worker > 0 && (queryParams['worker.id'] = `${data.worker}`);

        data.dateFrom && (queryParams[`endDate[after]`] = moment(data.dateFrom).format('YYYY-MM-DD HH:mm:ss'));
        data.dateTo && (queryParams[`startDate[before]`] = moment(data.dateTo).format('YYYY-MM-DD HH:mm:ss'));
    }
    // queryParams

    return getBookings(queryParams);
}

const defaultPagination = new PaginationResponse<Reservation>(30);

export function List() {

    const [loading, isLoading] = useState<boolean>(true);
    const [reservationPaging, setReservationPaging] = useState<PaginationResponse<Reservation>>(defaultPagination);
    const [searchData, setSearchData] = useState<SearchData>({
        page: defaultPagination.currentPage,
        itemsPerPage: defaultPagination.itemsPerPage
    })


    const changePage = (page: number) => {
        setSearchData({
            ...searchData,
            page,
        })
    }

    const makeSearch = (filterSearchData: SearchData) => {
        setSearchData(filterSearchData);
    }

    useEffect(() => {
        isLoading(true);
        searchRequest(searchData)
            .then((data) => {
                isLoading(false);
                setReservationPaging(data);
            })
    }, [searchData]);

    return (
        <div className="overflow-x-auto">
            <FiltersList onSearch={(data) => makeSearch(data)}/>
            <div className={'py-4 text-right'}>
                <Link to={'/dashboard/reservation/create'} className={'btn btn-primary'}>
                    <FontAwesomeIcon className={'mx-1'} icon={faPlusCircle}/> Dodaj rezerwację
                </Link>
            </div>
            <Spinner display={loading}>
                <table className="table table-zebra w-full">
                    <thead>
                    <tr>
                        <td></td>
                        <th>Pracownik</th>
                        <th>Od</th>
                        <th>Do</th>
                        <th>Status</th>
                        <th>Akcje</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        reservationPaging?.data?.length ? reservationPaging.data.map((e) => {
                            return (
                                <tr key={`res_${e.id}`}>
                                    <td>#{e.id}</td>
                                    <td style={{color: e.worker.color}}>{e.worker.name}</td>
                                    <td>
                                        {formatDate(e.startDate)}
                                    </td>
                                    <td>
                                        {formatDate(e.endDate)}
                                    </td>
                                    <td><FontAwesomeIcon icon={iconStatus(e.status)}/> {translateStatus(e.status)}</td>
                                    <td>
                                        <div className="tooltip mx-1" data-tip="Podgląd">
                                            <Link to={`/dashboard/reservation/${e.id}`}
                                                  className={'btn btn-primary btn-xs'}>
                                                <FontAwesomeIcon icon={faMagnifyingGlass}/>
                                            </Link>
                                        </div>
                                        <div className="tooltip mx-1" data-tip="Edytuj">
                                            <Link to={`/dashboard/reservation/${e.id}/edit`}
                                                  className={'btn btn-primary btn-xs'}>
                                                <FontAwesomeIcon icon={faPenToSquare}/>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) : (
                            <tr>
                                <td colSpan={6} className={'text-center'}>
                                    Nie znaleziono danych
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
                <div className={'flex justify-center py-4'}>
                    { reservationPaging && <Pagination pagination={reservationPaging} maxDisplayedPages={5} onChangePage={changePage}/>}
                </div>
            </Spinner>
        </div>
    )
}