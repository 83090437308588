import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {
    REPEAT_DAY, REPEAT_MONTH, REPEAT_TWO_WEEKS, REPEAT_WEEK,
    RepeatReservationForm
} from "../../components/RepeatReservationForm/RepeatReservationForm";
import {createPortal} from "react-dom";
import {useContext, useRef} from "react";
import {ReservationContext} from "../../contexts/ReservationContext";
import {Reservation} from "../../../models/reservation";
import moment from "moment";

const modalName = 'repeat-reservation-modal';

function RepeatReservationModalBody() {

    const closeRef = useRef<HTMLLabelElement>(null);

    const {reservation, updateReservation} = useContext(ReservationContext);

    return (<>
            <input type="checkbox" id={modalName} className="modal-toggle"/>
            <div className="modal">
                <div className="modal-box">
                    <label ref={closeRef} htmlFor={modalName} className="btn btn-xs btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="font-bold text-lg">
                        <FontAwesomeIcon icon={faPlus} className={'px-1'}/> Powtarzaj wizytę
                    </h3>
                    <RepeatReservationForm onSelectedRepeater={(values) => {

                        const newReservation = {childReservations: [] as Reservation[], ...reservation} as Reservation;

                        for (let i = 0; i < values.repeatNum; i++) {
                            const { startDate, endDate } = newReservation;

                            let newStartDate = moment(startDate).clone();
                            let newEndDate = moment(endDate).clone();

                            switch (values.repeatEvery) {
                                case REPEAT_DAY: {
                                    newStartDate = newStartDate
                                        .add(i+1, 'days');

                                    newEndDate = newEndDate
                                        .add(i+1, 'days');
                                    break;
                                }
                                case REPEAT_TWO_WEEKS:
                                case REPEAT_WEEK: {
                                    newStartDate = newStartDate
                                        .add(
                                            (i+1) * (values.repeatEvery === REPEAT_TWO_WEEKS ? 2 : 1),
                                            'weeks');

                                    newEndDate = newEndDate
                                        .add(
                                            (i+1) * (values.repeatEvery === REPEAT_TWO_WEEKS ? 2 : 1),
                                            'weeks');

                                    break;
                                }
                                case REPEAT_MONTH: {
                                    newStartDate = newStartDate
                                        .add((i+1), 'months');

                                    newEndDate = newEndDate
                                        .add((i+1), 'months');
                                    break;
                                }
                            }


                            newReservation.childReservations.push({
                                ...newReservation,
                                id:`TO_CREATED_${+new Date()}_${i}`,
                                status: "TO_CREATED",
                                startDate: newStartDate.format('YYYY-MM-DD HH:mm:ss'),
                                endDate: newEndDate.format('YYYY-MM-DD HH:mm:ss'),
                                childReservations: []
                            });
                        }

                        updateReservation(newReservation);
                        closeRef?.current?.click();
                    }}/>
                </div>
            </div>
        </>
    )
}

const RepeatReservationModalBodyPortaled = () => createPortal(<RepeatReservationModalBody/>, document.getElementById('modal-portal')!);

export function RepeatReservationModal() {
    return (
        <>
            <label htmlFor={modalName} className={'btn btn-primary btn-sm'}>
                <FontAwesomeIcon icon={faPlus} className={'px-1'}/> Powtarzaj wizytę
            </label>
            <RepeatReservationModalBodyPortaled/>
        </>
    )
}