import {ALLOWED_DATES_TYPE, isValidDate} from "../consts";
import {Worker} from "../../models/worker";
import {ReactNode, useEffect, useState} from "react";
import {checkReservation, getSelectedOffice} from "../../MainBooking/booking-api";
import {Office} from "../../models/office";
import {Reservation} from "../../models/reservation";

interface OfficeValidatorProps {
    startDate: ALLOWED_DATES_TYPE;
    endDate?: ALLOWED_DATES_TYPE;
    worker: Worker;
    office: Office;
    reservation?: Reservation;
    children: (params: {state: OfficeValidatorState}) => ReactNode;
}

export type OfficeValidatorState = 'NO_DATA' | 'CHECKING' | 'ALLOWED' | 'NOT_ALLOWED'

export function OfficeValidator({startDate, endDate, worker, office, reservation, children}: OfficeValidatorProps) {


    const [state, setState] = useState<OfficeValidatorState>('NO_DATA')

    const isValid = () => {
        return worker && office && startDate && endDate && isValidDate(startDate) && isValidDate(endDate);
    }

    useEffect(() => {
        if (isValid()) {
            setState('CHECKING');
            checkReservation(office, startDate, endDate, reservation)
                .then((e) => {
                    setState(e.available ? 'ALLOWED' : 'NOT_ALLOWED');
                });
        }
    }, [worker, startDate, endDate]);

    return <>
        { children({state}) }
    </>
}