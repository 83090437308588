import {useState} from "react";

export interface CalendarFiltersApi {
    displayCancelled: boolean;
}

export function CalendarFilters({onFilter}:{onFilter: (v: CalendarFiltersApi) => void}) {

    const [filters, updateFilters] = useState<CalendarFiltersApi>({
        displayCancelled: false
    })

    const sendEvent = () => {
        onFilter(filters);
    }

    return <>
        <div className={'flex flex-row justify-around'}>
            <div className="form-control w-72">
                <label className="label cursor-pointer">
                    <input
                        type="checkbox"
                        className="checkbox checkbox-info mx-4"
                        checked={filters.displayCancelled}
                        name={'display-cancelled'}
                        onChange={(e) => updateFilters({...filters, displayCancelled: e.target.checked})}
                    />
                    Pokaż aulowane rezerwacje
                </label>
            </div>
            <button className={'btn btn-primary'} type={'button'} onClick={() => sendEvent()}>Pokaż</button>
        </div>
    </>
}