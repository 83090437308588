import {Route, Routes} from "react-router-dom";
import {Navbar} from "./components/DashboardLayout/Navbar";
import {ReservationPreview} from "./pages/ReservationPreview/ReservationPreview";
import {List} from "./pages/List/List";
import {CreateReservation} from "./pages/NewReservationForm/CreateReservation";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Workers} from "./pages/Workers/Workers";
import {WorkerHourEditor} from "./pages/Workers/WorkerHourEditor";
import {EditReservation} from "./pages/NewReservationForm/EditReservation";

import "moment/locale/pl";
import {CalendarPage} from "./pages/Calendar/CalendarPage";
import {BookersList} from "./pages/BookersList/BookersList";

export function Dashboard() {
    return (
        <>
            <div className={'app-dashboard bg-gray-300 min-h-screen'}>
                <Navbar/>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="container p-4 xl:w-9/12 md:w-full mx-auto">
                    <Routes>
                        <Route path="/" element={<CalendarPage/>}/>
                        <Route path="/reservation/create" element={<CreateReservation/>}/>
                        <Route path="/reservation/:id" element={<ReservationPreview/>}/>
                        <Route path="/reservation/:id/edit" element={<EditReservation/>}/>
                        <Route path="/list" element={<List/>} />
                        <Route path="/workers" element={<Workers/>} />
                        <Route path="/workers/:id/working-hours" element={<WorkerHourEditor/>} />
                        <Route path="/bookers" element={<BookersList/>} />
                    </Routes>
                </div>

            </div>
        </>
    );
}
