import {CalendarFilters, CalendarFiltersApi} from "./CalendarFilters";
import {useState} from "react";
import {Calendar} from "./Calendar";
import {EventInput} from "@fullcalendar/react";
import moment from "moment/moment";
import {getBookings} from "../../dashboard-api";

export function CalendarPage() {

    const [calendarFilters, setCalendarFilters] = useState<CalendarFiltersApi>()

    const event$ = (info: { start: Date, end: Date }, successCallback: (events: EventInput[]) => void, ) => {
        const searchStartDate = moment(info.start).format('YYYY-MM-DD HH:mm:ss');
        const searchEndDate = moment(info.end).format('YYYY-MM-DD HH:mm:ss');

        const emptyEvents: EventInput[] = [];

        const currentDate = moment(info.start);
        const dateTo = moment(info.end);

        while (currentDate.isBefore(dateTo)) {
            const startDate = currentDate.clone().toDate();
            const endDate = currentDate.clone().add(60, 'minutes').toDate();

            emptyEvents.push({
                start: startDate,
                end: endDate,
                title: 'Dodaj',
                borderColor: 'blue',
                color: 'white',
                textColor: 'blue',
                extendedProps: {
                    status: 'TO_CREATE'
                }

            } as EventInput);
            currentDate.add(60, 'minutes');
        }

        const params: { [k: string]: string | string[] } = {};

        params[`startDate[before]`] = searchEndDate;
        params[`endDate[after]`] = searchStartDate;
        params[`itemsPerPage`] = `99999`;
        params[`status`] = [
            'TO_CREATED',
            'CREATED',
            'VERIFICATION',
            'CONFIRMED',
            ...(calendarFilters && calendarFilters.displayCancelled ? ['CANCELLED'] : [])
        ];

        getBookings(params)
            .then((obj) => {

                const events: EventInput[] = [];
                obj.data.forEach((e) => {
                    events.push({
                        id: e.id,
                        title: e.worker.name,
                        start: new Date(e.startDate),
                        end: new Date(e.endDate),
                        color: e.worker.color,
                        borderColor: e.status === 'CANCELLED' ? 'red' : e.worker.color,
                        extendedProps: {
                            status: 'ALREADY_CREATED',
                            reservation: e
                        }
                    } as EventInput);
                })
                successCallback(
                    [...emptyEvents, ...events]
                );
            })

    }

    return <>
        <div className={'card  bg-base-100'}>
            <div className="card-body">
                <h2 className={'card-title'}>Kalendarz rezerwacji</h2>
                <div className={'divider'}></div>
                <CalendarFilters onFilter={(v) => setCalendarFilters(v)}/>
                <div className={'divider'}></div>
                <Calendar
                    eventsData$={event$}
                />
            </div>
        </div>
    </>
}