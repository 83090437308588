import {useState} from "react";

interface BookersFilterParams {
    onSearch: (v?: string) => void
}

export function BookersFilter({onSearch}: BookersFilterParams) {

    const [searchedText, setSearchedText] = useState<string>();

    return (
        <>
            <form onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSearch(searchedText);
                return false;
            }}>
                <div className={'flex flex-row'}>
                    <div className="form-control flex-1">
                        <label className="label w-full">
                            <span className="label-text">Szukaj klienta</span>
                        </label>
                        <div className={'flex flex-row'}>
                            <input type="text"
                                   placeholder="Szukaj po imieniu, nazwisku, numeru telefonu, mailu..."
                                   name={'searchData'}
                                   onChange={(e) => setSearchedText(e.currentTarget.value)}
                                   className="flex-1 input input-bordered w-full"/>
                        </div>
                    </div>
                </div>
                <div className="justify-end py-2">
                    <button className="btn btn-primary" type={'submit'}>
                        Szukaj
                    </button>
                </div>
            </form>
        </>
    )
}