import {Bookers} from "../../../models/booker";

interface BookersTableProps {
    data: Bookers;
}

export function BookersTable({data}: BookersTableProps) {
    return (
        <>
            <table className="table table-zebra w-full">
                <thead>
                <tr>
                    <td></td>
                    <th>Imię</th>
                    <th>Nazwisko</th>
                    <th>Numer telefonu</th>
                    <th>Email</th>
                    <th>Akcje</th>
                </tr>
                </thead>
                <tbody>
                {
                    data && data.length > 0 ?
                        data.map((e) => (
                            <tr  key={`res_${e.id}`}>
                                <td></td>
                                <td>{e.firstName}</td>
                                <td>{e.lastName}</td>
                                <td>{e.phoneNumber}</td>
                                <td>{e.email}</td>
                                <td></td>
                            </tr>
                        ))
                            :
                            (
                        <tr>
                            <td colSpan={6} className={'text-center'}>
                                Nie znaleziono danych
                            </td>
                        </tr>
                    )
                }
                </tbody>

            </table>
        </>
    )
}