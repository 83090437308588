import {useFormikContext} from "formik";
import {AnyOfReservation} from "../../../../models/reservation";
import {ClientSelector} from "../../../components/ClientSelector";
import {Booker} from "../../../../models/booker";

export function ReservationBookerFields() {

    const {values, handleChange, setValues} = useFormikContext<AnyOfReservation>();

    const updateBooker = (b: Booker) => {

        const {firstName, lastName, email, phoneNumber, id} = b;

        setValues({
            ...values,
            ...{
                booker: {
                    ...values.booker,
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    id: `/api/bookers/${id}`
                }
            }
        })
    }

    return (
        <>
            <div className="form-control w-full max-w-xs">
                <ClientSelector onSelectClient={updateBooker}/>
            </div>
            <div className="form-control w-full max-w-xs">
                <label className="label">
                    <span className="label-text">Imię</span>
                </label>
                <input
                    type="text"
                    name="booker.firstName"
                    onChange={handleChange}
                    value={values.booker.firstName}
                    className={`input input-bordered w-full max-w-xs`}/>
            </div>
            <div className="form-control w-full max-w-xs">
                <label className="label">
                    <span className="label-text">Nazwisko</span>
                </label>
                <input
                    type="text"
                    name="booker.lastName"
                    onChange={handleChange}
                    value={values.booker.lastName}
                    className={`
                                                input input-bordered w-full max-w-xs
                                            `}/>
            </div>
            <div className="form-control w-full max-w-xs">
                <label className="label">
                    <span className="label-text">Email</span>
                </label>
                <input
                    type="text"
                    name="booker.email"
                    onChange={handleChange}
                    value={values.booker.email}
                    className={`
                                                input input-bordered w-full max-w-xs
                                            `}/>
            </div>
            <div className="form-control w-full max-w-xs">
                <label className="label">
                    <span className="label-text">Nr telefonu</span>
                </label>
                <input
                    type="text"
                    name="booker.phoneNumber"
                    onChange={handleChange}
                    value={values.booker.phoneNumber}
                    className={`
                                                input input-bordered w-full max-w-xs
                                            `}/>
            </div>
        </>
    )

}