import {Formik, FormikProps} from "formik";
import {ALLOWED_DATES_TYPE} from "../../consts";
import moment from "moment";
import {useEffect, useRef} from "react";

export const REPEAT_DAY = 'day';
export const REPEAT_WEEK = 'week';
export const REPEAT_TWO_WEEKS = 'two-week';
export const REPEAT_MONTH = 'month';

const ALLOWED_REPEAT = [REPEAT_DAY, REPEAT_WEEK, REPEAT_TWO_WEEKS, REPEAT_MONTH];

type REPEAT_EVERY = typeof ALLOWED_REPEAT[number]

export interface RepeatReservationValues {
    repeatEvery: REPEAT_EVERY;
    repeatNum: number;
    startDate: string}

export function RepeatReservationForm({onSelectedRepeater, startDate}: {onSelectedRepeater: (value: RepeatReservationValues) => void, startDate?: ALLOWED_DATES_TYPE}) {

    const initialValues: RepeatReservationValues = {
        repeatEvery: 'week',
        repeatNum: 4,
        startDate: !startDate ? '' : moment(startDate).format('YYYY-MM-DD HH:mm:ss')
    }

    const formikRef = useRef<FormikProps<RepeatReservationValues>>(null);

    useEffect(() => {

        if (formikRef && formikRef.current && formikRef.current.setFieldValue) {
            formikRef.current
                .setFieldValue(
                    'startDate',
                    !startDate ? '' : moment(startDate).format('YYYY-MM-DD HH:mm:ss')
                    )
        }
    }, [startDate])


    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                onSubmit={(values) => {
                    onSelectedRepeater(values);
                }}
            >
                {({
                      values,
                      handleChange,
                      handleSubmit
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={'flex md:flex-row flex-col'}>
                            <div className={'form-control w-full max-w-xs mx-2 '}>
                                <label className="label">
                                    <span className="label-text">Powtarzaj od</span>
                                </label>
                                <div className={'flex flex-row'}>
                                    <input
                                        className="flex-1 input-bordered select w-full max-w-xs"
                                        type={'datetime-local'}
                                        value={values.startDate}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className={'form-control w-full max-w-xs mx-2 '}>
                                <label className="label">
                                    <span className="label-text">Powtarzaj co</span>
                                </label>
                                <div className={'flex flex-row'}>
                                    <select
                                        className="flex-1 input-bordered select w-full max-w-xs"
                                        onChange={handleChange}
                                        name={'repeatEvery'}
                                        value={values.repeatEvery}
                                    >
                                        <option value={REPEAT_DAY}>Dzień</option>
                                        <option value={REPEAT_WEEK}>Tydzień</option>
                                        <option value={REPEAT_TWO_WEEKS}>Dwa tygodnie</option>
                                        <option value={REPEAT_MONTH}>Miesiąc</option>
                                    </select>
                                </div>
                            </div>
                            <div className={'form-control w-full max-w-xs mx-2 '}>
                                <label className="label">
                                    <span className="label-text">Ilość wizyt</span>
                                </label>
                                <div className={'flex flex-row'}>
                                    <input type="number" placeholder="Ilość"
                                           onChange={handleChange}
                                           min={1}
                                           name={'repeatNum'}
                                           value={values.repeatNum}
                                           className="flex-1 input input-bordered w-full max-w-xs"/>
                                </div>
                            </div>
                        </div>
                        <div className={'flex flex-row py-2 mx-2 justify-end'}>
                            <button type='submit' className={'btn btn-sm btn-primary'}>Dodaj wizyty</button>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}