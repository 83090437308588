import Calendar from "react-calendar";

interface CalendarFieldProps {
    name?: string;
    value: Date;
    onChange?: (v: Date) => void;
    setFieldValue?: (name: string, value: any, shouldValidate?: boolean) => void;
}

export function CalendarField(props: CalendarFieldProps) {

    const {value, onChange} = props;

    const change = (v: Date) => {
        onChange && onChange(v);
    }

    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);

    return (
        <Calendar
            value={value}
            locale={'pl-PL'}
            minDate={minDate}
            onChange={change}
            className={'w-full rounded-xl border-none m-auto'}/>
    )
}