import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faMagnifyingGlass, faRepeat, faTrash} from "@fortawesome/free-solid-svg-icons";
import {iconStatus, Reservation, translateStatus} from "../../../models/reservation";
import {formatDate} from "../../../utils/formatDate";
import {RepeatReservationModal} from "./RepeatReservationModal";
import {Link} from "react-router-dom";
import {useContext} from "react";
import {ReservationContext, ReservationContextData} from "../../contexts/ReservationContext";
import {OfficeSelector} from "../../components/OfficeSelector";

export function RepeatReservation({reservations, allowToAddRepeat}: { reservations?: Reservation[]; allowToAddRepeat: boolean}) {


    const {reservation, updateReservation} = useContext<ReservationContextData>(ReservationContext);

    return (
        <>
            <div className={'flex flex-col lg:flex-col'}>
                <div className={'flex flex-row'}>
                    <h2 className={'flex-1 py-4 text-lg'}>
                        <FontAwesomeIcon icon={faRepeat}/> Powtórzenia wizyt
                    </h2>
                    <div className={'flex-1 flex py-4 justify-end'}>
                        {allowToAddRepeat && <RepeatReservationModal/>}
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <table className="table w-full">
                        <thead>
                        <tr>
                            <th>Data od</th>
                            <th>Data do</th>
                            <th>Staus</th>
                            <th></th>
                            <th>Akcje</th>
                        </tr>
                        </thead>
                        <tbody>
                        {

                            (reservations || (reservation && reservation.childReservations) || []).length ? (reservations || (reservation && reservation.childReservations) || []).map((r, index) => {
                                return (
                                    <tr key={`rid-${r.id}`}>
                                        <td>{formatDate(r.startDate)}</td>
                                        <td>{formatDate(r.endDate)}</td>
                                        <td>
                                            <FontAwesomeIcon icon={iconStatus(r.status)}/> {translateStatus(r.status)}
                                        </td>
                                        <td>
                                            <OfficeSelector
                                                worker={r.worker}
                                                endDate={r.endDate}
                                                startDate={r.startDate}/>
                                        </td>
                                        <td>
                                            {
                                                r.status !== "TO_CREATED" ?
                                                (<>
                                                    <Link to={`/dashboard/reservation/${r.id}`}
                                                          className={'btn btn-xs btn-primary mx-1'}>
                                                        <FontAwesomeIcon icon={faMagnifyingGlass}/>
                                                    </Link>
                                                    <Link to={`/dashboard/reservation/${r.id}/edit`}
                                                          className={'btn btn-xs btn-primary mx-1'}>
                                                        <FontAwesomeIcon icon={faEdit}/>
                                                    </Link>
                                                </>) : (
                                                    <>
                                                        <button
                                                            type={'button'}
                                                            onClick={() => {
                                                                if (window.confirm('Usunąć to powtórzenie?')) {
                                                                    updateReservation({
                                                                        ...reservation,
                                                                        childReservations: reservation!.childReservations
                                                                            .filter((v) => v.id !== r.id)
                                                                    } as Reservation)
                                                                }
                                                            }}
                                                            className={'btn btn-primary btn-xs mx-1'}>
                                                            <FontAwesomeIcon icon={faTrash}/>
                                                        </button>
                                                    </>
                                                    )
                                            }
                                        </td>
                                    </tr>
                                );
                            }) : (
                                <tr>
                                    <td colSpan={3}>Brak powtórzeń rezerwacji</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}