import {Worker} from "./worker";
import {Booker} from "./booker";
import {
    faBan,
    faCalendarCheck,
    faCirclePlus,
    faHourglass, faPlus,
    faQuestion,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import {Office} from "./office";

export const ALLOWED_RESERVATION_STATUS = ['TO_CREATED', 'CREATED', 'CANCELLED', 'VERIFICATION','CONFIRMED'] as const;
export type RESERVATION_STATUS = typeof ALLOWED_RESERVATION_STATUS[number];

const TRANSLATED_STATUSES: {[k: string]: string} = {
    'TO_CREATED': 'Do utworzenia',
    'CREATED': 'Utworzone',
    'CANCELLED': 'Anulowane',
    'VERIFICATION': 'W trakcie weryfikacji',
    'CONFIRMED': 'Potwierdzone',
}
const STATUSES_ICONS: {[k: string]: IconDefinition} = {
    'TO_CREATED': faPlus,
    'CREATED': faCirclePlus,
    'CANCELLED': faBan,
    'VERIFICATION': faHourglass,
    'CONFIRMED': faCalendarCheck,
}

export const translateStatus = (status: RESERVATION_STATUS) => TRANSLATED_STATUSES[status] || 'Nieznany';
export const iconStatus = (status: RESERVATION_STATUS) => STATUSES_ICONS[status] || faQuestion;

export interface RepeatReservationData {
    unit: 'day' | 'week' | 'month';
    num: number;
}

export interface Reservation {
    id: string | number;
    startDate: string;
    endDate: string;
    worker: Worker;
    status: RESERVATION_STATUS;
    booker: Booker;
    additionalInfo: string;
    childReservations: Reservation[];
    parent: Reservation | null | string;
    office: Office | null | string;
    toCreatedData: RepeatReservationData[];
}

export type AnyOfReservation = {
    [k in keyof Reservation]?: any;
};

