import {BookersFilter} from "./BookersFilter";
import {useState} from "react";
import {Booker} from "../../../models/booker";
import {searchBookers} from "../../dashboard-api";
import {Spinner} from "../../../Shared/components/Spinner";
import {BookersTable} from "./BookersTable";
import {PaginationResponse} from "../../../models/paginationResponse";

export function BookersList() {

    const [bookers, setBookers] = useState<PaginationResponse<Booker>>();
    const [onRequest, setOnRequest] = useState<boolean>(false);

    const findBookers = async (v?: string) => {
        setOnRequest(true);
        const data = await searchBookers(v);
        setBookers(data);
        setOnRequest(false);
    }

    return (
        <>
            <div className={'pb-2'}>
                <BookersFilter
                    onSearch={(value?: string) => findBookers(value)}/>
            </div>
            <Spinner display={onRequest}>
                {bookers && bookers.data && bookers.data.length > 0 && <BookersTable data={bookers.data}/>}
            </Spinner>
        </>
    );
}