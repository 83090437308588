import {makePaginationRequest, makeRequest} from "../makeRequest";
import {AnyOfReservation, Reservation, RESERVATION_STATUS} from "../models/reservation";
import {PaginationResponse} from "../models/paginationResponse";
import {WorkerAvailabilities} from "../models/worker-availabilities";
import {Booker, Bookers} from "../models/booker";

const API_URL = process.env.REACT_APP_API_URL;

export function getBookings(searchData?: {[k:string]: string | string[]}): Promise<PaginationResponse<Reservation>> {

    let queryParams = '';
    if (searchData) {
        const queryParam = new URLSearchParams();
        Object.keys(searchData).forEach((key) => {
            if (Array.isArray(searchData[key])) {
                (searchData[key] as string[]).forEach((v) => {
                    queryParam.append(`${key}[]`, v);
                })
            } else {
                queryParam.append(key, `${searchData[key]}`);
            }
        })

        queryParams = `?${queryParam.toString()}`;
    }
    return makePaginationRequest<Reservation>(
        `${API_URL}/reservations${queryParams}`,
        "GET",
        null, { }
    );
}
export function getBooking(id: number | string): Promise<Reservation> {
    return makeRequest<Reservation>(`${API_URL}/reservations/${id}`, "GET");
}

export function updateReservationStatus(id: number | string, status: RESERVATION_STATUS): Promise<Reservation>  {
    return updateReservation(id, {status});
}

export function updateReservation(id: number | string, data: AnyOfReservation): Promise<Reservation> {
    return makeRequest<Reservation>(`${API_URL}/reservations/${id}`, "PATCH", {
        ...data,
        id: undefined
    })
}

export function createReservation(data: AnyOfReservation): Promise<Reservation> {
    return makeRequest<Reservation>(`${API_URL}/reservations`, "POST", {
        ...data
    })
}

export function createWorkerWorkingHours(data:{[k:string]: unknown}): Promise<WorkerAvailabilities>  {
    return makeRequest<WorkerAvailabilities>(
        `${API_URL}/worker_availabilities`,
        "POST",
        { ...data }
    )
}

export function removeWorkingTime(id: number | string): Promise<unknown> {
    return makeRequest<unknown>(
        `${API_URL}/worker_availabilities/${id}`,
        "DELETE"
    )
}

export function notifyReservationStatus(reservation:Reservation, type: 'CREATED' | 'UPDATED'): Promise<unknown> {
    return makeRequest<unknown>(`${API_URL}/notify-reservation/${reservation.id}/${type}`, "GET");
}

export function searchBookers(searchedData?: string): Promise<PaginationResponse<Booker>> {

    let query = '';
    if (searchedData) {
        const queryParams = new URLSearchParams();
        queryParams.append('global_filter', searchedData);
        query = `?${queryParams.toString()}`;
    }

    return makePaginationRequest<Booker>(
        `${API_URL}/bookers${query}`,
        "GET"
    );
}