import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarCheck} from "@fortawesome/free-solid-svg-icons";

export function ThankYouPage() {
    return (
        <div className="hero min-h-screen theme-ubuntu-psychoterapia">
            <div className="bookng-form w-3/4 flex-col lg:flex-row">
                <div className="flex py-5 items-center">
                    <div className="divider w-full">
                        <h1 className={'text-center text-xl md:text-3xl py-2'}>
                            <span className={'flex-shrink md:mx-4'}>
                                 <FontAwesomeIcon icon={faCalendarCheck}/> Rezerwacja wizyty
                            </span>
                        </h1>
                    </div>
                </div>
                <h2 className={'text-xl text-center'}>
                    Dziękujemy za dokonanie rezerwacji wizyty.
                </h2>
                <p className={'py-3 text-center'}>
                    Na podany adres email zostało wysłane potwierdzenie.
                    W celu jego potwierdzenia możemy próbować kontaktować telefonicznie na podany w formularzu numer.
                </p>
            </div>
        </div>
    )
}