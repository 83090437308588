import {useEffect, useState} from "react";
import {Worker} from "../../../models/worker";
import {getWorkers} from "../../../MainBooking/booking-api";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-solid-svg-icons";

export function Workers() {

    const [workers, setWorkers] = useState<Worker[]>([]);

    useEffect(() => {
        getWorkers()
            .then((d) => {
                setWorkers(d);
            })
    }, []);


    return (
        <>
            <table className="table table-zebra w-full">
                <thead>
                <tr>
                    <td>#</td>
                    <th colSpan={2}>Pracownik</th>
                    <th>Akcje</th>
                </tr>
                </thead>
                <tbody>
                {
                    workers.map((worker) => (
                        <tr key={`worker_${worker.id}`}>
                            <td>#{worker.id}</td>
                            <td colSpan={2}>
                                <div className={'flex flex-row'}>
                                <img
                                    alt={'worker avatar'}
                                    src={worker.picUrl}
                                    className={'h-8 w-8 rounded-full object-cover mx-1'}/>
                                {worker.name}
                                </div>
                            </td>
                            <td>
                                <div className="tooltip mx-1" data-tip="Edytuj godziny pracy">
                                    <Link to={`/dashboard/workers/${worker.id}/working-hours`}
                                          className={'btn btn-primary btn-xs'}>
                                        <FontAwesomeIcon icon={faClock}/>
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </>
    )
}