import {AnyOfReservation, Reservation} from "../../../models/reservation";
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarCheck, faPaperPlane, faUser, faSpider} from "@fortawesome/free-solid-svg-icons";
import {ReservationInformationFields} from "./components/ReservationInformationFields";
import {ReservationBookerFields} from "./components/ReservationBookerFields";
import {ALLOWED_DATES_TYPE} from "../../consts";
import {RepeatVisits} from "./components/RepeatVisits";
import moment from "moment";

export type ReservationFormWithRepeat = AnyOfReservation & {
    repeatVisits: { startDate: ALLOWED_DATES_TYPE, endDate: ALLOWED_DATES_TYPE }[]
}

interface ReservationFormProps {
    reservation?: Reservation;
    blockReservation?: boolean;
    onSubmit?: (data: ReservationFormWithRepeat) => void;
}

export function ReservationForm(props: ReservationFormProps) {

    const {blockReservation, onSubmit, reservation} = props;

    const initialValues: ReservationFormWithRepeat = {
        status: "CREATED",
        booker: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
        },
        // worker: 0,
        office: undefined,
        repeatVisits: [],
        ...reservation,
        startDate: !reservation || !reservation.startDate ? '' : moment(reservation.startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: !reservation || !reservation.endDate ? '' : moment(reservation.endDate).format('YYYY-MM-DD HH:mm:ss'),
        worker: reservation && reservation.worker ? reservation.worker.id : 0
    };

    return (
        <>
            <div className={'card'}>
                <div className={'card bg-base-100 shadow-xl'}>
                    <div className="card-body">
                        <h2 className="card-title">Formularz wizyty</h2>
                        <Formik<ReservationFormWithRepeat>
                            initialValues={initialValues}
                            onSubmit={(v) => {
                                if (onSubmit) {
                                    onSubmit(v);
                                }
                            }}>
                            {({
                                  handleSubmit,
                                  values
                              }) => (
                                <>
                                    <form onSubmit={handleSubmit}>
                                        <div className={'flex flex-col lg:flex-row'}>
                                            <div className={'flex-1 flex flex-col'}>
                                                <h2 className={'py-4 text-lg'}>
                                                    <FontAwesomeIcon icon={faCalendarCheck}/> Informacje
                                                </h2>
                                                <ReservationInformationFields/>
                                            </div>
                                            <div className={'flex-1 flex flex-col'}>
                                                <h2 className={'py-4 text-lg'}>
                                                    <FontAwesomeIcon icon={faUser}/> Informacje kontaktowe
                                                </h2>
                                                <ReservationBookerFields/>
                                            </div>
                                        </div>
                                        <RepeatVisits/>
                                        <div className={'flex flex-col lg:flex-row py-2 justify-center'}>
                                            {
                                                !blockReservation ? (
                                                    <button
                                                        className={'btn btn-primary'}
                                                        type={'submit'}>
                                                        <FontAwesomeIcon
                                                            icon={faPaperPlane}
                                                            className={'mx-1'}/>
                                                        Zapisz
                                                    </button>
                                                ) : (
                                                    <button className={'btn btn-primary'}
                                                            type={'button'}
                                                            disabled={true}>
                                                        <FontAwesomeIcon
                                                            icon={faSpider}
                                                            spin={true}
                                                            className={'mx-1'}/>
                                                    </button>)
                                            }
                                        </div>
                                    </form>
                                </>
                            )
                            }
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )

}