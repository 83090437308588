import {createContext} from "react";
import {Reservation} from "../../models/reservation";

export type ReservationContextData = {
    reservation: Reservation | null | undefined,
    updateReservation: (reservation: Reservation) => void
};

export const ReservationContext = createContext<ReservationContextData>({
    reservation: null,
    updateReservation: (reservation) => {}
});