import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faRepeat, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useFormikContext} from "formik";
import {ReservationFormWithRepeat} from "../ReservationForm";
import {createPortal} from "react-dom";
import {useRef} from "react";
import {ALLOWED_DATES_TYPE} from "../../../consts";
import {RepeatReservationForm} from "../../../components/RepeatReservationForm/RepeatReservationForm";
import {generateRepeatDates} from "./generateRepeatDates";
import {dateToApi, formatDate} from "../../../../utils/formatDate";
import {OfficeValidator} from "../../../components/OfficeValidator";
import {Moment} from "moment";
import {ReservationAvailableText} from "./ReservationAvailableText";
import {getWorker} from "../../../../MainBooking/booking-api";

const REPEAT_RESERVATION_MODAL = 'REPEAT_RESERVATION_MODAL';
const REPEAT_RESERVATIONS_FORM_KEY = 'repeatVisits';

const RepeatReservationModal = ({startDate}: { startDate: ALLOWED_DATES_TYPE }) => {

    const closeRef = useRef<HTMLLabelElement>(null);

    const {values, setFieldValue} = useFormikContext<ReservationFormWithRepeat>();
    return (
        <>
            <input type="checkbox" id={REPEAT_RESERVATION_MODAL} className="modal-toggle"/>
            <div className="modal">
                <div className="modal-box">
                    <label ref={closeRef} htmlFor={REPEAT_RESERVATION_MODAL}
                           className="btn btn-xs btn-circle absolute right-2 top-2">✕</label>
                    <h3 className="font-bold text-lg">
                        <FontAwesomeIcon icon={faPlus} className={'px-1'}/> Powtarzaj wizytę
                    </h3>
                    <RepeatReservationForm
                        startDate={new Date(dateToApi(startDate))}
                        onSelectedRepeater={async (value) => {

                            const worker = await getWorker(values.worker);
                            const {workingTime} = worker;
                            const dates = [
                                ...values.repeatVisits,
                                ...generateRepeatDates(value).map((time: Moment) => {
                                    const startDate = time;
                                    const endDate = time.clone().add(workingTime, 'minutes');

                                    return {
                                        startDate: dateToApi(startDate),
                                        endDate: dateToApi(endDate)
                                    }
                                })
                            ]
                            setFieldValue('repeatVisits', dates);
                            closeRef?.current?.click();
                        }}
                    />
                </div>
            </div>
        </>
    )
}

const RepeatVisitModalPortaled = (props: any) => createPortal(
    <RepeatReservationModal {...props}/>,
    document.getElementById('modal-portal')!
);

function RepeatVisitsModalButton({startDate}: { startDate: ALLOWED_DATES_TYPE }) {

    return (
        <div className={'flex flex-col lg:flex-row py-2 justify-end'}>
            <label htmlFor={REPEAT_RESERVATION_MODAL} className={'btn btn-primary btn-sm'}>
                <FontAwesomeIcon icon={faPlus} className={'px-1'}/> Powtarzaj wyzytę
            </label>
            <RepeatVisitModalPortaled startDate={startDate}/>
        </div>
    )
}

export function RepeatVisits() {

    const {values, setFieldValue} = useFormikContext<ReservationFormWithRepeat>();

    const updateDates = (data: unknown) => setFieldValue(REPEAT_RESERVATIONS_FORM_KEY, data);

    return (
        <>
            {values && values.worker && values.startDate && !isNaN(+new Date(values.startDate)) ?
                <RepeatVisitsModalButton startDate={values.startDate}/> : null}
            {values.repeatVisits && values.repeatVisits.length ?
                <div className={'flex flex-col lg:flex-col'}>
                    <div className={'flex flex-row'}>
                        <h2 className={'flex-1 py-4 text-lg'}>
                            <FontAwesomeIcon icon={faRepeat}/> Powtórzenia wizyt
                        </h2>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="table w-full">
                            <thead>
                            <tr>
                                <th colSpan={2}>Data powtórzenia</th>
                                <th></th>
                                <th>Akcje</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                values.repeatVisits.map((time, index) => {
                                    return (
                                        <tr key={`${index}_repeat_reservation_${time}`}>
                                            <td>{formatDate(time.startDate)}</td>
                                            <td>{formatDate(time.endDate)}</td>
                                            <td>
                                                <OfficeValidator
                                                    startDate={time.startDate}
                                                    endDate={time.endDate}
                                                    worker={values.worker}
                                                    office={values.office}>
                                                    {
                                                        ({state}) => (
                                                            <ReservationAvailableText status={state}/>
                                                        )
                                                    }
                                                </OfficeValidator>
                                            </td>
                                            <td>
                                                <button
                                                    type={'button'}
                                                    onClick={() => {
                                                        if (window.confirm('Usunąć to powtórzenie?')) {
                                                            const newValues = values.repeatVisits
                                                                .filter((v,i ) =>  i !== index);
                                                            updateDates(newValues);
                                                        }
                                                    }}
                                                    className={'btn btn-primary btn-xs mx-1'}>
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                : null}
        </>
    )
}