import './MainBooking.css';
import "react-calendar/dist/Calendar.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarCheck} from "@fortawesome/free-solid-svg-icons";
import {BookingForm, BookingFormData} from "./BookingForm";
import {useState} from "react";
import { Spinner } from '../Shared/components/Spinner';
import {useNavigate} from "react-router-dom";
import {bookReservation} from "./booking-api";
import moment from "moment/moment";

export function MainBooking() {

    const [onRequest, setOnRequest] = useState<boolean>(false);
    const [errors, setErrors] = useState<{[k: string]: string[]} | undefined>();
    const navigate = useNavigate();

    const tryToBook = async (values: BookingFormData) => {
        setOnRequest(true);

        bookReservation({
            ...values,
            selectedDate: moment(values.selectedDate).format('YYYY-MM-DD')
        })
            .then((data) => {
                setOnRequest(false);
                if (data.ok) {
                    navigate(`/thank-you`, {replace: true})
                } else {
                    setErrors(data.validationMessages)
                }
            })
            .catch((e) => {
                console.log(e);
                alert('Wystąpił błąd podczas rejestracji wizyty, sprawdź poprawność danych i spróbuj ponownie.')
                setOnRequest(false);
            })

    }
    return (
        <>
            <Spinner display={onRequest}>
                <div className="md:hero min-h-screen theme-ubuntu-psychoterapia">
                    <div className="bookng-form xl:w-3/4 sm:w-full flex-col lg:flex-row">
                        <div className="divider w-full">
                            <h1 className={'text-center text-3xl py-2'}>
                            <span className={'flex-shrink mx-4'}>
                                 <FontAwesomeIcon icon={faCalendarCheck}/> Rezerwacja wizyty
                            </span>
                            </h1>
                        </div>
                        <BookingForm onSubmit={tryToBook}/>
                        {errors && (
                            <>
                                <div className="alert alert-warning shadow-lg">
                                    <div>
                                        <div>
                                            <h3 className="font-bold">Popraw następujące błędy</h3>
                                            <div className="text-sm">
                                                <ul>
                                                    {
                                                        Object.keys(errors)
                                                            .map((e) => {
                                                                return errors[e].map((v) => {
                                                                    return (<li>{v}</li>)
                                                                })
                                                            })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Spinner>
        </>
    )
        ;
}