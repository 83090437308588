import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faUserTag} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import {Booker} from "../../models/booker";
import {createPortal} from "react-dom";
import {BookerFinder} from "../pages/BookersList/BookerFinder";

function ClientSelectorModal({opened, onClose}: { opened: boolean , onClose: (selectedClient?: Booker) => void}) {
    return (
        <>
            <input type="checkbox" id="client-selector-modal" className="modal-toggle" checked={opened} readOnly={true}/>
            <div className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Wybierz klienta</h3>
                    <div className="py-4">
                        <BookerFinder>
                            {
                                (onRequest, data) => {
                                    return <>
                                        {onRequest ?
                                            <>Szukam...</> :
                                            <>
                                                <ul className="menu bg-base-100 overflow-y-auto max-h-72">
                                                    {
                                                        data && data.data ?
                                                            data.data.map((e, k) => {
                                                                return (
                                                                <li className="py-3 sm:py-4"  key={`_booker_${e.id}`}>
                                                                    <div className="flex items-center space-x-4">
                                                                        <div className="flex-1 min-w-0">
                                                                            <span className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                                                {e.firstName} {e.lastName}
                                                                            </span>
                                                                            <div className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                                                {e.email}
                                                                                <div
                                                                                    className="divider divider-horizontal">
                                                                                </div>
                                                                                {e.phoneNumber}
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                                            <button
                                                                                onClick={() => onClose(e)}
                                                                                type={'button'}
                                                                                className={'btn btn-sm btn-primary'}>
                                                                                <FontAwesomeIcon icon={faCheck}/>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                )
                                                            }) : <></>
                                                    }
                                                </ul>
                                            </>
                                        }
                                    </>
                                }
                            }
                        </BookerFinder>
                    </div>
                    <div className="modal-action">
                        <label
                            htmlFor="client-selector-modal"
                            className="btn"
                            onClick={() => onClose()}>Anuluj</label>
                    </div>
                </div>
            </div>
        </>
    )
}

const ClientSelectorModalPortaled = (props: any) => createPortal(
    <ClientSelectorModal {...props}/>,
    document.getElementById('modal-portal')!
);

export function ClientSelector({onSelectClient}: {onSelectClient?: (value: Booker) => void }) {

    const [modalOpened, setModalOpened] = useState<boolean>(false);


    return (
        <>
            {modalOpened && <ClientSelectorModalPortaled
                opened={modalOpened}
                onClose={(booker: Booker) => {
                    setModalOpened(false);
                    if (booker && onSelectClient) {
                        onSelectClient(booker);
                    }
                }}
            />}
            <button className={'btn btn-primary btn-sm'}
                    type={'button'}
                    onClick={() => setModalOpened(true)}>
                <FontAwesomeIcon icon={faUserTag}/> Wybierz klienta z bazy
            </button>
        </>
    )
}