import {Reservation, RESERVATION_STATUS} from "../../models/reservation";
import {makeRequest} from "../../makeRequest";
import {API_URL} from "../shared";

export type CHANGE_STATUS_TYPE = 'ALL' | 'ONLY_THIS' | 'FROM_TODAY' | 'FROM_CURRENT';

export function changeReservationStatusRequest(reservation: Reservation, status: RESERVATION_STATUS, type: CHANGE_STATUS_TYPE) {

    const urlParams = new URLSearchParams({
        status,
        type,
        id: `${reservation.id}`
    });

    return makeRequest<['ok']>(
        `${API_URL}/dashboard/update-reservation-status?${urlParams.toString()}`,
        "GET",
    )
}