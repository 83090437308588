import {Route, Routes} from "react-router-dom";
import {MainBooking} from "./MainBooking";
import React from "react";
import {ThankYouPage} from "./ThankYouPage";

export function BookingPages() {
    return (
        <Routes>
            <Route path="/*" element={<MainBooking />} />
            <Route path="/thank-you" element={<ThankYouPage />} />
            <Route path="/:workerUrl" element={<MainBooking />} />
        </Routes>
    );
}